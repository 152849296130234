import { ResponseWorkLog, deleteWorkLog } from "api/workLog";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/member.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as ContentIcon } from "assets/writeIcon/workcontent.svg";
import {
  DeleteButton,
  EditButton,
  emptyData,
  formatDate,
} from "components/Common";
import DialogDelete from "components/DialogDelete";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isEditedState, modeState, openDialogState } from "recoil/stateStore";
import { workListState } from "recoil/stateStore";

interface ViewSalesFormProps {
  workLogData: ResponseWorkLog;
  onClose: (event: any) => void;
  setDeletedId: Function;
}

const ViewSalesForm = ({
  workLogData,
  onClose,
  setDeletedId,
}: ViewSalesFormProps) => {
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [openDialog, setOpenDialog] = useRecoilState(openDialogState);
  const setWorkList = useSetRecoilState(workListState);
  const setIsEdit = useSetRecoilState(isEditedState);

  const onEdit = () => {
    setIsEdit(true);
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    const response = await deleteWorkLog(workLogData.work_id);
    setWorkList((prev: any) =>
      prev.filter((works: ResponseWorkLog) => works.work_id !== works.work_id)
    );
    setOpenDialog(false);
    setDeletedId(workLogData.work_id);
    onClose(false);
    console.log(response.data);
  };

  return (
    <>
      <div className="write_list_wrapper">
        <div className="work_option">
          <div className="write_date">
            <div className="div_icon" style={{ marginRight: "15px" }}>
              <CalendarIcon style={{ marginRight: "4px" }} /> 일시
            </div>
            <span>{formatDate(workLogData.work_date)}</span>
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} />
              고객사 부서
            </div>
            <span>
              {workLogData.member?.member_id === null ||
              workLogData.member?.member_id === -1
                ? "-"
                : workLogData.member?.member_department}
            </span>
          </div>
          <div>
            <div className="div_icon">
              <MemberIcon style={{ marginRight: "4px" }} /> 고객사 담당자
              <span style={{ color: "red" }}></span>
            </div>
            <span>
              {emptyData(
                workLogData.member?.member_name +
                  " " +
                  workLogData.member?.member_role
              )}
            </span>
          </div>
          <div className="textarea_wrapper">
            <div className="div_icon" style={{ marginBottom: "4px" }}>
              <ContentIcon style={{ marginRight: "4px" }} />
              계획일정
            </div>
            <textarea
              value={workLogData.plan ? workLogData.plan : ""}
              name="plan"
              disabled={true}
            />
          </div>
          <div className="textarea_wrapper">
            <div className="div_icon" style={{ marginBottom: "4px" }}>
              <ContentIcon style={{ marginRight: "4px" }} />
              대응내용 및 결과
            </div>
            <textarea
              value={workLogData.work_content ? workLogData.work_content : ""}
              name="work_content"
              disabled={true}
            />
          </div>
        </div>
        <div className="write_bottom">
          {loginUserId === workLogData.user?.user_id ? (
            <DeleteButton onClickHandler={() => setOpenDialog(true)} />
          ) : (
            ""
          )}
          {loginUserId === workLogData.user?.user_id ? (
            <EditButton onClickHandler={onEdit} />
          ) : (
            ""
          )}
          <DialogDelete
            open={openDialog}
            onCancel={() => setOpenDialog(false)}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};
export default ViewSalesForm;
