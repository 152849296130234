import { client, getErrorMessage } from "./client";

export interface CarLogUser {
  user_id: string;
  user_name: string;
  dep_name: string;
  role_name: string;
}

interface NullCompany {
  comp_id: number | null;
  comp_name: string;
  comp_sido: string;
  comp_sgg: string;
}

interface NullMember {
  member_id: number | null;
  member_name: string;
  member_department: string;
  member_role: string;
  member_phone: string;
}

export interface ResponseWorkLog {
  work_id: number;
  work_date: Date;
  user: CarLogUser;
  company: NullCompany;
  member: NullMember;
  work_option: string;
  meeting: string;
  work_content: string;
  plan: string;
  progress: string;
}

export interface WriteWorkLog {
  work_date: Date;
  user_id: string;
  comp_id: number | null;
  member_id: number | null;
  work_option: string;
  work_content: string;
  meeting: string;
  plan: string;
  progress: string;
}

export interface SearchParams {
  search_type: number;
  keyword: string;
  start_date: string;
  end_date: string;
  page: number;
  pageSize: number | null;
  sort: number;
  user_id?: string | null;
  car_id?: number | null;
  comp_id?: number | null;
  is_personal?: number | null;
  work_id?: number | null;
  progress?: string | "";
}

// export interface RequestPatchWorkLog {
//   work_date: Date;
//   comp_id: number | null;
//   member_id: number | null;
//   work_option: string;
//   meeting: string;
//   work_content: string;
//   plan: string;
//   progress: string;
// }

// 응답: 업무일지 조회
export interface WorkLogList {
  total_count: number;
  content: ResponseWorkLog[];
}

//업무일지 목록
export const getWorkLogs = async (
  user_id: string,
  comp_id: number | null,
  start_date: string,
  end_date: string
) => {
  try {
    // const token = localStorage.getItem("token");
    const response = await client.get("/worklogs", {
      headers: {
        // Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
      params: { user_id, comp_id, start_date, end_date },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 업무일지 상세보기
export const getWorkLog = async (id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.get(`/worklog/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(response.data, "response.data")
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 업무일지 삭제
export const deleteWorkLog = async (work_id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.delete(`/worklog/${work_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    throw {
      status: error.status,
      message: error.message,
    };
  }
};

// 업무일지 등록
export const postWorkLog = async (writeLog: WriteWorkLog) => {
  try {
    // const token = localStorage.getItem("token");
    const response = await client.post<ResponseWorkLog>("/worklogs", writeLog, {
      headers: {
        // Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });
    console.log(response);
  } catch (error: any) {
    console.log(error);
    throw {
      status: error.status,
      message: error.message,
    };
  }
};

// 업무일지 수정
export const patchWorkLog = async (
  work_id: number,
  writeLog: Partial<ResponseWorkLog>
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(`/worklog/${work_id}`, writeLog, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

export const getSales = async (
  comp_id: number
  // searchParams: SearchParams
) => {
  try {
    const { data } = await client.get(
      `/saleslogs?comp_id=${comp_id}`
      // {
      //   params: searchParams,
      // }
    );

    return data;
  } catch (error: any) {
    throw {
      status: error.status,
      message: error.message,
    };
  }
};

//영업일지 단일 데이터 > 업무일지에 띄울 데이터 get
export const getSale = async (work_id: number) => {
  try {
    const response = await client.get<ResponseWorkLog>(`worklogs/${work_id}`);
    console.log(response);
  } catch (error: any) {
    throw {
      status: error.status,
      message: error.message,
    };
  }
};
