import "styles/CompanyDetailStyle.css";
import "styles/AdminMenuStyle.css";
import { useState, useEffect } from "react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import Card from "@mui/material/Card";
import {
  dateFilterParams,
  formatDate,
  textFilterParams,
  truncateString,
} from "components/Common";
import { Company, getCompany } from "api/company";
import Grid from "components/CompanyGrid";
import { Member, getCompanyMembers } from "api/member";
import { useLocation, useNavigate } from "react-router-dom";
import CreateWorkDrawer from "components/drawer/CreateWorkDrawer";
import { ResponseWorkLog, getSales } from "api/workLog";
import ViewMemberDrawer from "components/drawer/ViewMemberDrawer";
import ViewSalesDrawer from "components/drawer/ViewSalesDrawer";
import EditCompDrawer from "components/drawer/EditCompDrawer";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";
import DialogText from "components/DialogText";
import { ValueFormatterParams } from "ag-grid-enterprise";

interface mRowData {
  member_id: number;
  member_department: string;
  member_name: string;
  member_role: string;
  member_phone: string;
  member_email: string;
  member_note: string;
  comp_id: number;
}
interface sRowData {
  work_id: number;
  work_date: string;
  user_name: string;
  dep_name: string;
  work_option: string;
  comp_name: string;
  member_department: string;
  member_name: string;
  plan: string;
  work_content: string;
  progress: string;
  comp_id: number;
}

export default function CompanyDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [memberRowData, setMemberRowData] = useState<mRowData[]>([]);
  const [salesRowData, setSalesRowData] = useState<sRowData[]>([]);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const [memberDrawerOpen, setMemberDrawerOpen] = useState<boolean>(false);
  const [compDrawerOpen, setCompDrawerOpen] = useState<boolean>(false);
  const [salesDrawerOpen, setSalesDrawerOpen] = useState<boolean>(false);
  const [selectedMemberId, setSelectedMemberId] = useState<number>(-1);
  const [selectedSalesId, setSelectedSalesId] = useState<number>(-1);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [openDialog, setOpenDialog] = useState(false);
  const [companyData, setCompanyData] = useState<Company>();
  const [updatedMemberData, setUpdatedMemberData] = useState<any>();
  const [updatedSalesData, setUpdatedSalesData] = useState<any>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>(-1);

  useEffect(() => {
    const callCompanyDetail = async () => {
      try {
        let response = await getCompany(location.state.id);
        const companyData = response.data;
        setCompanyData(companyData);

        if (companyData.comp_id) {
          response = await getCompanyMembers(companyData.comp_id);
          const memberData = response.data.content;
          const mappedMembers = memberData.member_list.map((item: Member) => ({
            member_id: item.member_id,
            member_name: item.member_name,
            member_department: item.member_department,
            member_role: item.member_role,
            member_phone: item.member_phone,
            member_email: item.member_email,
            member_note: item.member_note,
            comp_id: companyData.comp_id,
          }));
          setMemberRowData(mappedMembers);

          response = await getSales(companyData.comp_id);
          // setTotalItems(response.total_count);
          const mappedSales = response.data.content.map((item: any) => ({
            work_id: item.work_id,
            work_date: formatDate(item.work_date),
            user_name: item.user.user_name,
            dep_name: item.user.dep_name,
            work_option: item.work_option,
            comp_name: item.company.comp_name,
            member_department: item.member.member_department,
            member_name: item.member.member_name,
            plan: item.plan,
            work_content: item.work_content,
            progress: item.progress,
            comp_id: companyData?.comp_id,
          }));
          setSalesRowData(mappedSales);
          setIsSaved(false);
        }
      } catch (error) {
        console.error("Error fetching work logs:", error);
      }
    };
    callCompanyDetail();
  }, [isSaved, isEdit]);

  // const handleDataClick = async (comp_id: number, member_id: number) => {
  //   console.log(member_id);
  //   try {
  //     const response = await getMember(comp_id, member_id);
  //     setSelectMember(response);
  //     toggleDrawer(<NewMemberWrite />);
  //     setIsDrawerOpen(true);
  //     setMode("view");
  //   } catch (error) {
  //     handleError(error);
  //   }
  // };

  // 업체담당자 정보
  const memberColumnDefs: ColDef[] = [
    {
      headerName: "부서",
      field: "member_department",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
      cellRenderer: (params: ValueFormatterParams) => {
        return <p>{params.value ? params.value : "-"}</p>;
      },
    },
    {
      headerName: "성명",
      field: "member_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
    {
      headerName: "직책",
      field: "member_role",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
    {
      headerName: "전화번호",
      field: "member_phone",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
    {
      headerName: "이메일",
      field: "member_email",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
      cellRenderer: (params: ValueFormatterParams) => {
        return <p>{params.value ? params.value : "-"}</p>;
      },
    },
    {
      headerName: "비고",
      field: "member_note",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
  ];

  // 영업일지
  const salesColumnDefs: ColDef[] = [
    {
      headerName: "일시",
      field: "work_date",
      filter: "agTextColumnFilter",
      filterParams: dateFilterParams,
      flex: 0.7,
    },
    {
      headerName: "부서",
      field: "member_department",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.5,
    },
    {
      headerName: "업체 담당자",
      field: "member_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.5,
    },
    {
      headerName: "계획일정",
      field: "plan",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "대응내용 및 결과",
      field: "work_content",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
  ];

  const closeDrawer = () => {
    if (userDrawerOpen === "view" && !isEdit) {
      closeDialog();
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitSuccess = () => {
    setIsSaved(true);
    closeDialog();
  };

  useEffect(() => {
    if (userDrawerOpen === "edit" && !isEdit) {
      closeDialog();
    }
  }, [isEdit]);

  const closeDialog = () => {
    setMemberDrawerOpen(false);
    setSalesDrawerOpen(false);
    setCompDrawerOpen(false);
    setOpenDialog(false);
    setIsEdit(false);
  };

  const handleSetOpen = (status: boolean, type: string) => {
    if (type === "members") {
      setMemberDrawerOpen(true);
      setUserDrawerOpen("view");
    }
    if (type === "newMembers") {
      setMemberDrawerOpen(true);
      setUserDrawerOpen("create");
    }
    if (type === "editCompany") {
      setCompDrawerOpen(true);
      setIsEdit(true);
      setUserDrawerOpen("edit");
    }
    if (type === "sales") {
      setSalesDrawerOpen(true);
      setUserDrawerOpen("view");
    }
  };

  const selectedId = (type: string, id: number) => {
    if (type === "member") {
      setSelectedMemberId(id);
    }
    if (type === "sales") {
      setSelectedSalesId(id);
    }
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="comp_detail_header">
          <div>
            <span style={{ fontWeight: "700", color: "#4e20a5" }}>
              {companyData?.comp_name}
            </span>
            <span style={{ fontSize: "20px" }}> 정보</span>
          </div>
        </div>
        <div className="comp_detail_info">
          <div className="comp_categories">
            <span>업종</span> <span>주소</span> <span>전화번호</span>
            <span>팩스번호</span>
          </div>
          <div className="comp_info">
            <span>{companyData?.sectors}</span>
            <span>
              {truncateString(
                companyData?.comp_sido_nm +
                " " +
                companyData?.comp_sgg_nm +
                " " +
                companyData?.comp_address,
                40
              )}
            </span>
            <span>{companyData?.comp_tel}</span>
            <span>{companyData?.comp_fax}</span>
          </div>
          <div className="comp_update_btn">
            <button
              style={{ cursor: "pointer" }}
              onClick={() => handleSetOpen(true, "editCompany")}
            >
              수정
            </button>
            <EditCompDrawer
              open={userDrawerOpen === "edit" && compDrawerOpen}
              onClose={closeDrawer}
              compId={location.state.id}
            />
          </div>
        </div>
        <div className="comp_member_info">
          <div className="member_info_title">
            <p style={{ fontSize: "20px" }}>업체 담당자 정보</p>
            <button
              className="write_button"
              style={{ width: "130px", height: "40px" }}
              onClick={() => handleSetOpen(true, "newMembers")}
            >
              업체 담당자 등록
            </button>
          </div>
          <Card
            className="month_card"
            style={{ height: "400px", marginBottom: "20px" }}
          >
            <Grid
              columnDefs={memberColumnDefs}
              rowData={memberRowData}
              type="members"
              setOpen={handleSetOpen}
              setId={selectedId}
              memberData={updatedMemberData}
              deletedId={deletedId}
            />
            <ViewMemberDrawer
              open={userDrawerOpen === "view" && memberDrawerOpen}
              onClose={closeDrawer}
              memberId={selectedMemberId}
              setUpdatedData={setUpdatedMemberData}
              setDeletedId={setDeletedId}
            />
            <CreateWorkDrawer
              open={userDrawerOpen === "create" && memberDrawerOpen}
              onClose={closeDrawer}
              onSubmitSuccess={handleSubmitSuccess}
              type={"Member"}
            />
          </Card>
        </div>
        <div className="comp_sale_info">
          <div className="sales_info_title">
            <p style={{ fontSize: "20px" }}>영업일지</p>
            <button
              className="write_button"
              style={{ width: "fit-content", height: "40px" }}
              onClick={() =>
                navigate("/", { state: { id: companyData?.comp_id } })
              }
            >
              <span style={{ fontWeight: "700" }}>
                {companyData?.comp_name}
              </span>
              업무일지로 이동
            </button>
          </div>
          {/* <WorkLogDrawer type={"sales"} open={workLogDrawerOpen} onClose={closeDrawer} mode={mode} workId={selectedSalesId} /> */}
          <Card />
          <Card
            className="month_card"
            style={{ height: "400px", marginBottom: "20px" }}
          >
            <Grid
              columnDefs={salesColumnDefs}
              rowData={salesRowData}
              type="sales"
              setOpen={handleSetOpen}
              setId={selectedId}
              salesData={updatedSalesData}
              deletedId={deletedId}
            />
            <ViewSalesDrawer
              open={userDrawerOpen === "view" && salesDrawerOpen}
              onClose={closeDrawer}
              workId={selectedSalesId}
              setUpdatedData={setUpdatedSalesData}
              setDeletedId={setDeletedId}
            />
            <DialogText
              open={openDialog}
              content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
              onCancel={() => setOpenDialog(false)}
              onClose={closeDialog}
            />
          </Card>
        </div>
      </div>
    </>
  );
}
