import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { ReactComponent as SettingIcon } from "assets/setting.svg";
import { ReactComponent as LogoutIcon } from "assets/logout.svg";
import "styles/SideStyle.css";
import LogoImg from "assets/logo.png";
import { useSetRecoilState } from "recoil";
import {
  filterUserState,
  isAccordionExpendedState,
  selectedIndexState,
  userState,
} from "recoil/stateStore";

export default function Header() {
  const setSelectedIndex = useSetRecoilState(selectedIndexState);
  const setExpanded = useSetRecoilState(isAccordionExpendedState);
  const setFilterUser = useSetRecoilState(filterUserState);
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);

  const handleNavClick = (page: string) => {
    navigate(`/${page}`);
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_role");
    localStorage.removeItem("is_admin");
    setUser({ user_id: "", password: "" });
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          borderBottom: "1px solid #eee",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            paddingLeft: { sm: "16px", xs: "8px" },
            paddingRight: { sm: "16px", xs: "8px" },
            minHeight: { sm: "60px", xs: "56px" },
            // maxWidth: "1920px",
          }}
        >
          <img
            style={{
              width: "140px",
              marginLeft: "15px",
              cursor: "pointer",
            }}
            id="logo"
            src={LogoImg}
            alt="CNI Logo"
            onClick={() => {
              setSelectedIndex(`panel1-0`);
              setExpanded("panel1");
              setFilterUser("");
              navigate("/");
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            color="black"
            fontSize={"13px"}
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" }, alignItems: "right" }}
          >
            <GetId />
          </Typography>
          <Box
            sx={{ marginRight: "10px", display: { xs: "none", md: "flex" } }}
          >
            <button className="btn_setting">
              <SettingIcon
                width={"20px"}
                height={"20px"}
                color="red"
                viewBox="0 0 24 24"
              />
            </button>
            <button
              className="btn_logout"
              onClick={() => handleNavClick("login")}
            >
              로그아웃 <LogoutIcon />
            </button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

function GetId() {
  const loginUserId = localStorage.getItem("user_id");
  const loginUserName = localStorage.getItem("user_name");

  return (
    <div>
      안녕하세요,{" "}
      {loginUserName ? (
        <span style={{ fontWeight: "bold" }}>
          {loginUserName}({loginUserId})
        </span>
      ) : (
        " "
      )}{" "}
      님
    </div>
  );
}
