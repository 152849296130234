import { ResponseYearPlan, getAnnualPlan } from "api/yearPlan";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditYearForm from "components/editForm/EditYearForm";
import ViewYearForm from "components/viewForm/ViewYearForm";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
    open: boolean;
    onClose: (event: any) => void;
    planId: number;
    setUpdatedData: Function;
    setDeletedId: Function;
}

const ViewYPlanDrawer: React.FC<FormDrawerProps> = ({
    open,
    onClose,
    planId,
    setUpdatedData,
    setDeletedId,
}) => {
    const [isEdit, setIsEdit] = useRecoilState(isEditedState)
    const [yearPlanData, setYearPlanData] = useState<ResponseYearPlan>({} as ResponseYearPlan);


    useEffect(() => {
        console.log(planId, "planId")
        const callWorkLog = async () => {
            const yPlanData = await getAnnualPlan(planId);
            const total = yPlanData.data.first_half + yPlanData.data.second_half;
            // console.log(total, "total")
            const updatedData = { ...yPlanData.data, total: total }
            setYearPlanData(updatedData);
            setUpdatedData(updatedData)
            console.log(updatedData, "updatedData")
        }
        if (planId !== -1 && !isEdit) {
            callWorkLog();
        }

    }, [planId, isEdit]);

    return (
        <WorkLogDrawer open={open} onClose={onClose}>
            {isEdit ? (
                <div className="write_head">연간 계획서 수정</div>
            ) : (
                <div className="write_head">연간 계획서 상세보기</div>
            )}
            {isEdit ? (
                <EditYearForm
                    yearPlanData={yearPlanData}
                    setYearPlanData={setYearPlanData}
                    // setIsEdit={setIsEdit}
                    planId={planId}
                />
            ) : (
                <ViewYearForm
                    yearPlanData={yearPlanData}
                    // setIsEdit={setIsEdit}
                    onClose={onClose}
                    setDeletedId={setDeletedId}
                />
            )}
        </WorkLogDrawer>
    );
};

export default ViewYPlanDrawer;