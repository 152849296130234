import {
  DeleteButton,
  EditButton,
} from "components/Common";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/name.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as RoleIcon } from "assets/writeIcon/role.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as MailIcon } from "assets/writeIcon/mail.svg";
import { ResponseNewMember, deleteMember } from "api/member";
import DialogDelete from "components/DialogDelete";
import { useState } from "react";
import { Company } from 'api/company';
import { useSetRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";


interface ViewMemberFormProbs {
  memberData: ResponseNewMember;
  onClose: (event: any) => void;
  setDeletedId: Function;
}

const ViewMemberForm = ({ memberData, onClose, setDeletedId }: ViewMemberFormProbs) => {
  const [openDialog, setOpenDialog] = useState(false);
  const compData = memberData.company;
  const setIsEdit = useSetRecoilState(isEditedState);

  const onEdit = () => {
    setIsEdit(true);
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    const response = await deleteMember(compData.comp_id, memberData.member_id);
    // setMemberList((prev: any) =>
    //     prev.filter((members: Member) => members.member.id !== members.member.id)
    // );
    setOpenDialog(false);
    setDeletedId(compData.comp_id)
    onClose(false);
    console.log(response.data);
  };

  return (
    <>
      <div className="write_list_wrapper" >
        <div className="work_option">
          <div>
            <div className="div_icon">
              <CompanyIcon style={{ marginRight: "4px" }} />
              업체명
            </div>
            <span>{compData?.comp_name}</span>
          </div>
          <div>
            <div className="div_icon">
              <MemberIcon style={{ marginRight: "4px" }} />
              성명
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <span>{memberData.member_name}</span>
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} />
              부서
            </div>
            <span>{memberData.member_department}</span>
          </div>
          <div>
            <div className="div_icon">
              <RoleIcon style={{ marginRight: "4px" }} />
              직책
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <span>{memberData.member_role}</span>
          </div>
          <div className="input_phone_number">
            <div className="div_icon">
              <PhoneIcon style={{ marginRight: "4px" }} />
              연락처
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <span >
              {memberData.member_phone}
            </span>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div className="div_icon">
              <MailIcon style={{ marginRight: "4px" }} />
              이메일
            </div>
            <span>
              {memberData.member_email
                ? memberData.member_email
                : "-"}
            </span>
          </div>
        </div>

        <div className="log_note">
          비고
          <textarea
            value={memberData.member_note ? memberData.member_note : ""}
            style={{ height: "140px", marginTop: "4px", color: "#000" }}
            name="work_content"
            disabled={true}
          />
        </div>
        <div className="write_bottom">
          <>
            <DeleteButton onClickHandler={() => setOpenDialog(true)} />
            <EditButton onClickHandler={onEdit} />
            <DialogDelete
              open={openDialog}
              onCancel={() => setOpenDialog(false)}
              onDelete={handleDelete}
            />
          </>
        </div>
      </div>
    </>
  );
}
export default ViewMemberForm;