import { CounselingType, getConsulting, getCounselingList } from "api/counseling";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditConsForm from "components/editForm/EditConsForm";
import ViewConsForm from "components/viewForm/ViewConsForm";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
    open: boolean;
    onClose: (event: any) => void;
    consId: number;
    setUpdatedData: Function;
    setDeletedId: Function;
}
const ViewConsDrawer: React.FC<FormDrawerProps> = ({
    open,
    onClose,
    consId,
    setUpdatedData,
    setDeletedId,
}) => {
    const [isEdit, setIsEdit] = useRecoilState(isEditedState);
    const [consData, setConsData] = useState<CounselingType>({} as CounselingType);


    useEffect(() => {
        const callWorkLog = async () => {
            const consData = await getConsulting(consId);
            setConsData(consData.data);
            setUpdatedData(consData.data);
            console.log(consData.data)
        }
        if (consId !== -1 && !isEdit) {
            callWorkLog();
        }
    }, [consId, isEdit]);


    return (
        <WorkLogDrawer open={open} onClose={onClose}>
            {isEdit ? (
                <div className="write_head">상담 일지 수정</div>
            ) : (
                <div className="write_head">상담 일지 상세보기</div>
            )}
            {isEdit ? (
                <EditConsForm
                    consData={consData}
                    setConsData={setConsData}
                // setIsEdit={setIsEdit}
                />
            ) : (
                <ViewConsForm
                    consData={consData}
                    // setIsEdit={setIsEdit}
                    onClose={onClose}
                    setDeletedId={setDeletedId}
                />
            )}
        </WorkLogDrawer>
    );
}

export default ViewConsDrawer;
