import { ResponseMonthPlan, getMonthlyPlan } from "api/monthPlan";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditMonthForm from "components/editForm/EditMonthForm";
import ViewMonthForm from "components/viewForm/ViewMonthForm";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
    open: boolean;
    onClose: (event: any) => void;
    planId: number;
    setUpdatedData: Function;
    setDeletedId: Function;
}

const ViewPlanDrawer: React.FC<FormDrawerProps> = ({
    open,
    onClose,
    planId,
    setUpdatedData,
    setDeletedId,
}) => {
    const [isEdit, setIsEdit] = useRecoilState(isEditedState)
    const [monthPlanData, setMonthPlanData] = useState<ResponseMonthPlan>({} as ResponseMonthPlan);


    useEffect(() => {
        const callWorkLog = async () => {
            const mPlanData = await getMonthlyPlan(planId);
            setMonthPlanData(mPlanData.data);
            setUpdatedData(mPlanData.data)
            console.log(mPlanData.data)
        }
        if (planId !== -1 && !isEdit) {
            callWorkLog();
        }
    }, [planId, isEdit]);


    return (
        <WorkLogDrawer open={open} onClose={onClose}>
            {isEdit ? (
                <div className="write_head">월간 계획서 수정</div>
            ) : (
                <div className="write_head">월간 계획서 상세보기</div>
            )}
            {isEdit ? (
                <EditMonthForm
                    monthPlanData={monthPlanData}
                    setMonthPlanData={setMonthPlanData}
                    // setIsEdit={setIsEdit}
                    planId={planId}
                />
            ) : (
                <ViewMonthForm
                    monthPlanData={monthPlanData}
                    // setIsEdit={setIsEdit}
                    onClose={onClose}
                    setDeletedId={setDeletedId}
                />
            )}
        </WorkLogDrawer>
    );
};

export default ViewPlanDrawer;