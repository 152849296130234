import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getErrorMessage } from "api/client";
import {
  createPart,
  createRole,
  deletePart,
  deleteRole,
  Department,
  getPartList,
  getRoleList,
  Role,
  updatePart,
  updateRole,
} from "api/user";
import { successDelete } from "components/Common";
import DialogDelete from "components/DialogDelete";
import { useErrorHandler } from "components/ErrorHandler";
import { useEffect, useMemo, useState } from "react";

interface NewProps {
  onClose: (event: any) => void;
}

const NewPartRole: React.FC<NewProps> = ({ onClose }) => {
  const [partList, setPartList] = useState<Department[]>([]);
  const [roleList, setRoleList] = useState<Role[]>([]);
  const [IsEdit, setIsEdit] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editText, setEditText] = useState<string>("");
  const [editMode, setEditMode] = useState<string>("");
  const [partName, setPartName] = useState("");
  const [roleName, setRoleName] = useState("");

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [deleteTargetType, setDeleteTargetType] = useState<
    "part" | "role" | null
  >(null);
  const [deleteTargetId, setDeleteTargetId] = useState<number | null>(null);

  const handleError = useErrorHandler();

  useEffect(() => {
    const callPartRoleList = async () => {
      try {
        const resPart = await getPartList();
        setPartList(resPart.data);
        const resRole = await getRoleList();
        setRoleList(resRole.data);
      } catch (error) {
        handleError(error);
      }
    };
    callPartRoleList();
  }, [setPartList, setRoleList]);

  // 부서 등록
  const handleAddPart = async () => {
    try {
      const data = { dep_name: partName };
      const newPart = await createPart(data);
      setPartList((prevPartList) => [...prevPartList, newPart]);
      // setPartList((prev) =>
      //   prev.map((part) =>
      //     part.dep_id === updatePart.dep_id ? updatePart : part
      //   )
      // );
    } catch (error) {
      handleError(error);
    }
  };

  // 부서 수정 버튼
  const handlePartEditClick = (dep_id: number) => {
    const part = partList.find((dep) => dep.dep_id === dep_id)?.dep_name;
    setEditMode("부서");
    setIsEdit(true);
    setEditingIndex(dep_id);
    if (part) {
      setEditText(part);
    }
  };

  // 부서 정보 업데이트
  const handleUpdatePart = async (depId: number) => {
    try {
      const data = { dep_name: editText };
      await updatePart(depId, data);

      setEditingIndex(null);
      setIsEdit(false);
      setEditText("");
      setEditMode("");
      setPartList((part) => part.map((item) => item.dep_id === depId ? { ...item, dep_name: editText } : item))
    } catch (error) {
      handleError(error);
    }
  };

  // 직책 등록
  const handleAddRole = async () => {
    try {
      const data = { role_name: roleName };
      const newRole = await createRole(data);

      // 기존 리스트에 새로 추가된 직책을 추가
      setRoleList((prevRoleList) => [...prevRoleList, newRole]);
    } catch (error) {
      handleError(error);
    }
  };

  // 직책 수정 버튼
  const handleRoleEdit = (role_id: number) => {
    const roles = roleList.find((role) => role.role_id === role_id)?.role_name;
    setEditMode("직책");
    setIsEdit(true);
    setEditingIndex(role_id);
    if (roles) {
      setEditText(roles);
    }
  };

  // 직책 업데이트
  const handleUpdateRole = async (roleId: number) => {
    try {
      const data = { role_name: editText };
      await updateRole(roleId, data);

      setEditingIndex(null);
      setIsEdit(false);
      setEditText("");
      setEditMode("");
      setRoleList((role) => role.map((item) => item.role_id === roleId ? { ...item, role_name: editText } : item))
    } catch (error) {
      handleError(error);
    }
  };

  // 취소
  const handleCancel = () => {
    setEditingIndex(null);
    setIsEdit(false);
    setEditText("");
    setEditMode("");
  };

  //부서/직책 삭제
  const handleDeleteClick = (type: "part" | "role", id: number) => {
    setDeleteTargetType(type);
    setDeleteTargetId(id);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setOpenDialog(false);
      if (deleteTargetType === "part" && deleteTargetId !== null) {
        const res = await deletePart(deleteTargetId);
        setPartList((prevPartList) =>
          prevPartList.filter((part) => part.dep_id !== deleteTargetId)
        );
      } else if (deleteTargetType === "role" && deleteTargetId !== null) {
        const res = await deleteRole(deleteTargetId);
        setRoleList((prevRoleList) =>
          prevRoleList.filter((role) => role.role_id !== deleteTargetId)
        );
      }
      successDelete();
    } catch (error) {
      handleError(error);
    }
  };

  const StyledTableCell = useMemo(() => {
    return styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#f4f4f4",
        color: theme.palette.common.black,
        height: 40,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
      },
    }));
  }, []);

  const StyledTableRow = useMemo(() => {
    return styled(TableRow)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        height: 40,
      },
    }));
  }, []);

  return (
    <div className="write_list_wrapper">
      <div className="part_role__wrap">
        <div className="part_wrap">
          <h4 style={{ fontWeight: 600 }}>부서 목록</h4>
          <div className="add_wrap">
            <input
              placeholder="추가할 부서명을 입력해주세요."
              onChange={({ target }) => setPartName(target.value)}
            />
            <button onClick={handleAddPart} className="cancel_button">
              + 추가
            </button>
          </div>
          <TableContainer
            component={Paper}
            style={{ overflow: "auto", height: "315px" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: "Highlight" }}>
                <StyledTableRow>
                  <StyledTableCell>부서명</StyledTableCell>
                  <StyledTableCell>관리</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody className="part_table_row">
                {partList.map((rowData, rowIndex) => (
                  <StyledTableRow key={rowData.dep_id}>
                    {editingIndex === rowData.dep_id && editMode === "부서" ? (
                      <>
                        <StyledTableCell>
                          <input
                            type="text"
                            name="partName"
                            value={editText}
                            onChange={(e) => {
                              setEditText(e.target.value);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell className="row_button">
                          <button
                            className="write_button"
                            onClick={() => handleUpdatePart(rowData.dep_id)}
                          >
                            저장
                          </button>
                          <button
                            className="cancel_button"
                            style={{ background: "#FFF" }}
                            onClick={handleCancel}
                          >
                            취소
                          </button>
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell>{rowData.dep_name}</StyledTableCell>
                        <StyledTableCell>
                          <button
                            className="write_button"
                            onClick={() => {
                              handlePartEditClick(rowData.dep_id);
                            }}
                          >
                            수정
                          </button>
                          <button
                            style={{
                              backgroundColor: "#fff",
                              color: "tomato",
                              border: "1px solid #ccc",
                            }}
                            onClick={() => {
                              console.log(rowData, "rowData");
                              handleDeleteClick("part", rowData.dep_id);
                            }}
                          >
                            삭제
                          </button>
                          {/* <DialogDelete
                            open={openPartDialog}
                            onCancel={() => setOpenPartDialog(false)}
                            onDelete={handlePartDelete}
                          /> */}
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="role_wrap">
          <h4 style={{ fontWeight: 600 }}>직책 목록</h4>
          <div className="add_wrap">
            <input
              placeholder="추가할 직책명을 입력해주세요."
              onChange={({ target }) => setRoleName(target.value)}
            />
            <button onClick={handleAddRole} className="cancel_button">
              + 추가
            </button>
          </div>
          {/* </div> */}
          <TableContainer
            component={Paper}
            style={{ overflow: "auto", height: "315px" }}
          >
            {/* <div className="part_list_table"> */}
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>직책명</StyledTableCell>
                  <StyledTableCell>관리</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody className="part_table_row">
                {roleList.map((rowData, rowIndex) => (
                  <StyledTableRow key={rowData.role_id}>
                    {editingIndex === rowData.role_id && editMode === "직책" ? (
                      <>
                        <StyledTableCell>
                          <input
                            type="text"
                            value={editText}
                            onChange={(e) => {
                              setEditText(e.target.value);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <button
                            className="write_button"
                            onClick={() => handleUpdateRole(rowData.role_id)}
                          >
                            저장
                          </button>
                          <button
                            className="cancel_button"
                            style={{ background: "#FFF" }}
                            onClick={handleCancel}
                          >
                            취소
                          </button>
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell>{rowData.role_name}</StyledTableCell>
                        <StyledTableCell>
                          <button
                            className="write_button"
                            onClick={() => handleRoleEdit(rowData.role_id)}
                          >
                            수정
                          </button>
                          <button
                            style={{
                              backgroundColor: "#fff",
                              color: "tomato",
                              border: "1px solid #ccc",
                            }}
                            onClick={() =>
                              handleDeleteClick("role", rowData.role_id)
                            }
                          >
                            삭제
                          </button>
                          {/* <DialogDelete
                            open={openRoleDialog}
                            onCancel={() => setOpenRoleDialog(false)}
                            onDelete={handleRoleDelete}
                          /> */}
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {/* </div> */}
          </TableContainer>
        </div>
        <DialogDelete
          open={openDialog}
          onCancel={() => setOpenDialog(false)}
          onDelete={handleDeleteConfirm}
        />
        <div className="write_bottom">
          <button onClick={onClose} style={{ backgroundColor: "#fff" }}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewPartRole;
