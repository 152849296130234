import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { getUserList, User } from "api/user";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  filterUserState,
  loginUserInfoState,
  selectedIndexState,
} from "recoil/stateStore";

interface SubItemUserListProps {
  panel: string;
  setSidebarTitle: (title: string) => void;
  title: string;
  page: string;
}

function SubItemUserList({
  panel,
  page,
  setSidebarTitle,
  title,
}: SubItemUserListProps) {
  const [userList, setUserList] = useState<User[]>([]);
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedIndexState);
  const loginUserInfo = useRecoilValue(loginUserInfoState);
  const setFilterUser = useSetRecoilState(filterUserState);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(loginUserInfo, "loginUserInfo");
    const callUserList = async () => {
      try {
        const { data } = await getUserList(false);
        const customOrder = customUserList.map((user) => user.user_name);
        const sortedData = data.content.sort((a: any, b: any) => {
          const indexA = customOrder.indexOf(a.user_name); // customUserList 항목 정렬
          const indexB = customOrder.indexOf(b.user_name); // 기타 List 정렬

          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
          } else if (indexA !== -1) {
            return -1;
          } else if (indexB !== -1) {
            return 1;
          } else {
            return 0;
          }
        });
        setTimeout(() => {
          setUserList(sortedData);
        }, 300);
        console.log(sortedData, "유저리스트");
      } catch (e) {
        console.log(e);
      }
    };

    callUserList();
  }, []);

  // 사용자 순서 정렬
  const customUserList = [
    { user_name: "이규환" },
    { user_name: "김기산" },
    { user_name: "윤상민" },
    { user_name: "장현석" },
    { user_name: "김도연" },
    { user_name: "조건희" },
    { user_name: "김윤중" },
    { user_name: "안효림" },
    { user_name: "이지연" },
    { user_name: "이수정" },
  ];

  const isLoaded = userList.length > 0;
  const skeletonStyle = useMemo(
    () => ({
      sx: {
        height: "40px",
      },
    }),
    []
  );

  const handleItemClick =
    (panel: string, index: number) =>
      (event: React.MouseEvent<HTMLDivElement>) => {
        console.log(panel, index, "index");
        setSelectedIndex(`${panel}-${index}`);
        setSidebarTitle(title);
        navigate(page);

        const targetUser = userList[index - 2];

        if (targetUser) {
          setFilterUser(userList[index - 2].user_id);
        } else if (index === 0) {
          setFilterUser("");
        } else {
          setFilterUser(loginUserInfo.user_id);
        }
      };

  return (
    <List component="div" disablePadding>
      <ListItemButton
        style={{ listStyleType: "-moz-initial" }}
        sx={{ fontWeight: "600" }}
        key={0}
        id="listSubBtn"
        className={selectedIndex === `${panel}-${0}` ? "selected" : ""}
        onClick={handleItemClick(panel, 0)}
      >
        <ListItemText primary="전체" />
      </ListItemButton>
      <ListItemButton
        style={{ listStyleType: "-moz-initial", fontWeight: "600" }}
        key={1}
        id="listSubBtn"
        className={selectedIndex === `${panel}-${1}` ? "selected" : ""}
        onClick={handleItemClick(panel, 1)}
      >
        <ListItemText
          primary={`${loginUserInfo.user_name} ${loginUserInfo.user_role}`}
        />
      </ListItemButton>
      {/* {isLoaded &&
        userList
          .filter(
            ({ user_id }: { user_id: string }) =>
              loginUserInfo.user_id !== user_id
          )
          .map((item: User, index: number) => (
            <ListItemButton
              style={{ listStyleType: "-moz-initial" }}
              key={index + 2}
              id="listSubBtn"
              className={
                selectedIndex === `${panel}-${index + 2}` ? "selected" : ""
              }
              onClick={handleItemClick(panel, index + 2)}
            >
              <ListItemText
                primary={`${item.user_name}  ${item.role.role_name}`}
              />
            </ListItemButton>
          ))} */}
      {isLoaded &&
        userList.map((item: User, index: number) => {
          if (loginUserInfo.user_id == item.user_id) {
            return null;
          }

          if (item.user_id == "9999") {
            return null;
          }

          return (
            <ListItemButton
              style={{ listStyleType: "-moz-initial" }}
              key={index + 2}
              id="listSubBtn"
              className={
                selectedIndex === `${panel}-${index + 2}` ? "selected" : ""
              }
              onClick={handleItemClick(panel, index + 2)}
            >
              <ListItemText
                primary={`${item.user_name}  ${item.role.role_name}`}
              />
            </ListItemButton>
          );
        })}
      {!isLoaded && (
        <Box sx={{ pl: "42px" }}>
          <Skeleton {...skeletonStyle} />
          <Skeleton {...skeletonStyle} />
          <Skeleton {...skeletonStyle} />
        </Box>
      )}
    </List>
  );
}

export default SubItemUserList;
