import axios, { Axios } from "axios";
import { client, getErrorMessage } from "./client";
import { User } from "./user";
import { ResponseCar } from "./car";

export interface ResponseCarLog {
  carlog_id: number;
  car_date: Date;
  purpose: string;
  purpose_detail: string;
  car: ResponseCar;
  user: User;
  loc_start: string;
  acc_start: number;
  loc_end: string;
  acc_end: number;
  drive_distance: number;
  carlog_note: string;
  is_approval: boolean;
}

// 신규 차량운행일지 등록 요청
export interface NewCarLog {
  car_id: number;
  car_date: Date;
  purpose: string;
  purpose_detail: string;
  user_id: string;
  loc_start: string;
  acc_start: number;
  loc_end: string;
  acc_end: number;
  drive_distance: number;
  carlog_note: string;
  is_approval: boolean;
}

// 응답: 업무일지 조회
export interface CarLogList {
  total_count: number;
  content: ResponseCarLog[];
}

//차량운행일지 목록
export const getCarLogs = async (
  user_id: string | null,
  car_id: number | null,
  start_date: string,
  end_date: string
) => {
  try {
    const response = await client.get("/carlogs", {
      headers: {},
      params: {
        user_id,
        car_id: car_id ? car_id : undefined,
        start_date,
        end_date,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 차량일지 등록
export const postCarLog = async (newCarLog: NewCarLog) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.post("/carlogs", newCarLog, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log(error);

    throw new Error(getErrorMessage(error));
  }
};

// 차량일지 수정
export const updateCarLog = async (
  carlog_id: number,
  updateCarLog: NewCarLog
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(`/carlog/${carlog_id}`, updateCarLog, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 차량일지 승인
export const patchCarLogApproval = async (
  carlog_id: number,
  updateCarLog: NewCarLog
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(
      `/admin/carlog/${carlog_id}`,
      updateCarLog,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

//차량운행일지 상세보기
export const getCarLog = async (id: number) => {
  try {
    const response = await client.get(`/carlog/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 차량일지 삭제
export const deleteCarLog = async (carlog_id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.delete(`/carlog/${carlog_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// // 차량일지 다운로드
// export const downLoadCarLog = async (car_id: number) => {
//   try {
//     const a = document.createElement("a");
//     a.style.display = "none";
//     a.href = `http://localhost:8000/api/download/carlog/${car_id}`;
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//   } catch (error: any) {
//     throw new Error(getErrorMessage(error));
//   }
// };
// 차량일지 다운로드
export const downLoadCarLog = async (car_id: number) => {
  try {
    // 서버로부터 엑셀 파일 다운로드 요청
    const response = await axios.get(`http://localhost:8000/api/download/carlog/${car_id}`, {
      responseType: "blob", // 파일을 blob 형식으로 수신
    });

    // 파일을 다운로드 받기 위한 URL 생성
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute(
      "download",
      response.headers["content-disposition"].split("filename=")[1]
    ); // 서버에서 보낸 파일 이름 설정
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Download failed", error);
  }
};


