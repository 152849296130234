import { DeleteButton, EditButton } from "components/Common";
import { ReactComponent as UserIdIcon } from "assets/writeIcon/userid.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/name.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as RoleIcon } from "assets/writeIcon/role.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as MailIcon } from "assets/writeIcon/mail.svg";
import { ReactComponent as Car } from "assets/writeIcon/car.svg";
import { ReactComponent as AdminIcon } from "assets/writeIcon/admin.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { ReactComponent as Delete } from "assets/writeIcon/delete.svg";
import { useState } from "react";
import { deleteUser, User } from "api/user";

import { Box, Checkbox } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isEditedState, loginUserInfoState } from "recoil/stateStore";
import DialogDelete from "components/DialogDelete";
import { userListState } from "recoil/stateStore";

interface UserViewFormProps {
  // setIsEdit: (state: boolean) => void;
  userInfo: User;
  onClose: (event: any) => void;
  setIsDeleted: Function;
}

const UserViewForm: React.FC<UserViewFormProps> = ({
  userInfo,
  // setIsEdit,
  onClose,
  setIsDeleted,
}) => {
  const loginUserInfo = useRecoilValue(loginUserInfoState);
  const [openDialog, setOpenDialog] = useState(false);
  const setUserList = useSetRecoilState(userListState);
  const setIsEdit = useSetRecoilState(isEditedState);

  const handleDelete = async () => {
    const response = await deleteUser(userInfo.user_id);
    setUserList((prev: any) =>
      prev.filter((user: User) => user.user_id !== userInfo.user_id)
    );
    setOpenDialog(false);
    onClose(false);
    setIsDeleted(true);
    console.log(response.data);
  };

  return (
    <>
      <Box className="write_list_wrapper" sx={{ px: "24px", py: "20px" }}>
        <div className="write_content">
          <div>
            <div className="div_icon">
              <UserIdIcon style={{ marginRight: "4px" }} />
              사번
            </div>
            <span>{userInfo.user_id}</span>
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} />
              부서
            </div>
            <span>{userInfo.department?.dep_name || "-"}</span>
          </div>
          <div>
            <div className="div_icon">
              <RoleIcon style={{ marginRight: "4px" }} />
              직책
            </div>
            <span>{userInfo.role?.role_name || "-"}</span>
          </div>
          <div>
            <div className="div_icon">
              <UserIcon style={{ marginRight: "4px" }} />
              성명
            </div>
            <span>{userInfo.user_name}</span>
          </div>
          <div>
            <div className="div_icon">
              <MailIcon style={{ marginRight: "4px" }} />
              이메일
            </div>
            <span>{userInfo.email}</span>
          </div>
          <div className="input_phone_number">
            <div className="div_icon">
              <PhoneIcon style={{ marginRight: "4px" }} />
              연락처(업무)
            </div>
            <span>{userInfo.work_phone_number || "-"}</span>
          </div>
          <div className="input_phone_number">
            <div className="div_icon">
              <PhoneIcon style={{ marginRight: "4px" }} />
              연락처(개인)
            </div>
            <span>{userInfo.phone_number || "-"}</span>
          </div>
          {/* <div className="admin_box">
            <div className="div_icon">
              <Car
                style={{
                  marginRight: "4px",
                  fill: "black",
                }}
              />
              차량 유무
            </div>
            <div>
              <Checkbox
                sx={{
                  borderColor: "",
                }}
                checked={userInfo.is_car ? true : false}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div> */}
          <div className="admin_box">
            <div className="div_icon">
              <AdminIcon
                style={{
                  marginRight: "4px",
                  fill: "black",
                  fillOpacity: "0.5",
                }}
              />
              관리자 계정
            </div>
            {/* <input
              type="checkbox"
              className="check_box"
              style={{ cursor: "default" }}
              checked={userInfo.is_admin}
              readOnly
            /> */}
            <div>
              <Checkbox
                sx={{
                  borderColor: "",
                }}
                checked={userInfo.is_admin ? true : false}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>

          <div className="textarea_wrapper">
            <div className="div_icon">
              <NoteIcon style={{ marginRight: "4px" }} /> 비고
            </div>
            <textarea
              name="work_content"
              value={userInfo.user_note || ""}
              disabled
            />
          </div>
          <div>
            <div className="div_icon">
              <Delete
                style={{
                  marginRight: "4px",
                  fill: "black",
                  fillOpacity: "0.5",
                }}
              />
              삭제 여부
            </div>
            <span>
              <Checkbox
                sx={{
                  borderColor: "",
                }}
                checked={userInfo.is_deleted ? true : false}
                inputProps={{ "aria-label": "controlled" }}
              />
            </span>
          </div>
        </div>
        <div className="write_bottom">
          {loginUserInfo.is_admin ?
            <>
              <DeleteButton onClickHandler={() => setOpenDialog(true)} />
              <EditButton onClickHandler={() => setIsEdit(true)} />
              <DialogDelete
                open={openDialog}
                onCancel={() => setOpenDialog(false)}
                onDelete={handleDelete}
              />
            </>
            : ""
          }
        </div>
      </Box>
    </>
  );
};
export default UserViewForm;
