import { serializeWorkLog } from "util/serializer";
import {
  convertRegionName,
  formatDate,
  formatProgress,
  formatPurpose,
  handleWorkOptionText,
} from "./Common";

const UpdatedRowData = (newData: any) => {
  let data;
  console.log(newData, "newData")
  if (newData.work_id) {
    data = serializeWorkLog(newData);
  } else if (newData.cons_id) {
    data = {
      ...newData,
      cons_id: newData.cons_id,
      cons_date: formatDate(newData.cons_date),
      comp_name: newData.company?.comp_name,
      member_name:
        newData.member?.member_name + " " + newData.member?.member_role,
      project: newData.project,
      item: newData.item,
      amount: newData.amount,
      price: newData.price,
      sum: Number(newData.amount * newData.price),
      result: formatProgress(newData.result),
    };
  } else if (newData.carlog_id) {
    data = {
      ...newData,
      carlog_id: newData.carlog_id,
      carlog_date: formatDate(newData.car_date),
      car: newData.car.car_num + " (" + newData.carModel + ")",
      purpose: formatPurpose(newData.purpose),
      user: newData.user.user_name,
      loc_start: newData.loc_start,
      acc_start: newData.acc_start,
      loc_end: newData.loc_end,
      acc_end: newData.acc_end,
      drive_distance: newData.drive_distance,
      work_drive_distance: newData.car.work_drive_distance,
      carlog_note: newData.carlog_note,
      is_approval: newData.is_approval,
    };
  } else if (newData.member_id) {
    data = {
      ...newData,
      member_id: newData.member_id,
      member_name: newData.member_name,
      member_department: newData.member_department,
      member_role: newData.member_role,
      member_phone: newData.member_phone,
      member_email: newData.member_email,
      member_note: newData.member_note,
    };
  } else if (newData.car_id) {
    data = {
      ...newData,
      car_model: newData.car_model,
      car_num: newData.car_num,
      user_id: newData.user?.user_id,
      user: newData.user?.user_name + " " + newData.user?.role?.role_name,
      home_address: newData.home_address || "-",
      work_address: newData.work_address || "-",
      distance: newData.distance || 0,
      is_personal: newData.is_personal,
      is_deleted: newData.is_deleted,
    };
  } else if (newData.user_id) {
    data = {
      ...newData,
      user_id: parseInt(newData.user_id),
      user_name: newData.user_name,
      department: newData.department?.dep_name,
      role: newData.role?.role_name,
      email: newData.email,
      work_phone_number: newData.work_phone_number,
      phone_number: newData.phone_number,
      is_car: newData.is_car, // 변환된 필드
      is_admin: newData.is_admin, // 변환된 필드
      is_deleted: newData.is_deleted,
    };
  }

  return data;
};

export default UpdatedRowData;
