import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { autoSelectCompIdState, modeState } from "recoil/stateStore";
import "styles/WriteStyle.css";
import { Company, getTotalCompanyList } from "api/company";

export default function AutoCompleteCompany() {
  const [inputValue, setInputValue] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [autoSelectCompId, setAutoSelectCompId] = useRecoilState(
    autoSelectCompIdState
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const mode = useRecoilValue(modeState);

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const companiesData = await getTotalCompanyList();
        console.log(companiesData, "companiesData");
        setCompanyList(companiesData.data.content);
      } catch (error) {
        console.log(error);
      }
    }
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (autoSelectCompId) {
      const selectedCompany = companyList.find(
        (company) => company.comp_id === autoSelectCompId
      );
      if (selectedCompany) {
        setInputValue(selectedCompany.comp_name);
      }
    }
  }, [autoSelectCompId, companyList]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    const filtered = companyList.filter((company) =>
      company.comp_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filtered);
    setShowList(true);
  };

  const handleSelectCompany = (company: Company) => {
    setInputValue(company.comp_name);
    setShowList(false);
    setAutoSelectCompId(company.comp_id);
  };

  const handleResetClick = () => {
    setInputValue("선택하세요");
    setShowList(false);
    setAutoSelectCompId(null);
  };

  return (
    <div
      className="auto_company"
      style={{ position: "relative", width: "60%", minWidth: "216px" }}
      ref={wrapperRef}
    >
      <input
        type="text"
        value={inputValue}
        placeholder="고객사 선택"
        style={{ padding: "12px 10px", width: "100%" }}
        onChange={handleInputChange}
        onFocus={() => {
          setShowList(true);
          setFilteredCompanies(companyList);
        }}
      />
      {showList && (
        <div className="auto_options" style={{ width: "100%" }}>
          <div
            key=""
            onClick={handleResetClick}
            style={{ minHeight: "25px", padding: "5px 0px 5px 5px" }}
          >
            선택하세요
          </div>
          {filteredCompanies.map((company, index) => (
            <div
              key={company.comp_id}
              onClick={() => handleSelectCompany(company)}
              style={{ minHeight: "25px", padding: "5px 0px 5px 5px" }}
            >
              {company.comp_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
