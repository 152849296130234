import { ResponseCarLog, getCarLog, getCarLogs } from "api/carLog";
import { Company, getCompany } from "api/company";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditCompanyForm from "components/editForm/EditCompanyForm";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
    open: boolean;
    onClose: (event: any) => void;
    compId: number;
}
const EditCompDrawer: React.FC<FormDrawerProps> = ({
    open,
    onClose,
    compId,
}) => {
    const [isEdit, setIsEdit] = useRecoilState(isEditedState);
    const [compData, setCompData] = useState<Company>({} as Company)

    useEffect(() => {
        console.log(isEdit, "isEdit 확인")
        const callCompany = async () => {
            const compData = await getCompany(compId);
            setCompData(compData.data);
            console.log(compData.data, compId, "comp.data")
        }
        if (compId !== -1) {
            callCompany();
        }
    }, [isEdit, compId]);

    return (
        <WorkLogDrawer open={open} onClose={onClose}>
            {isEdit ? (
                <div className="write_head">업체 상세정보 수정</div>
            ) : (
                <></>
            )}
            {isEdit ? (
                <EditCompanyForm
                    companyData={compData}
                    setCompData={setCompData}
                    // setIsEdit={setIsEdit}
                    onClose={onClose}
                    compId={compId}
                />
            ) : (
                <></>
            )}
        </WorkLogDrawer>
    );
}
export default EditCompDrawer;