import { ChangeEvent, useEffect, useId, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  dialogContentState,
  isModalPwdOpenState,
  loginUserInfoState,
  openDialogState,
  userEditState,
} from "recoil/stateStore";
import ModalPassword from "components/ModalPassword";
import { ReactComponent as SaveIcon } from "assets/save.svg";
import { ReactComponent as CancelIcon } from "assets/cancel.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { useErrorHandler } from "components/ErrorHandler";
// import { DialogText } from "components/DialogText";
import PhoneForm from "components/writeForm/PhoneForm";
import "styles/UserInfoStyle.css";
import { sidebarTitleState } from "recoil/commonState";
import {
  Department,
  getPartList,
  getRoleList,
  getUser,
  RequestUser,
  Role,
  updateUserByAdmin,
  updateUserBySelf,
  UserInfo,
} from "api/user";
import { SelectInput } from "components/SelectInput";
import { userInfo } from "os";

export async function fetchUserInfo(loginUserId: string) {
  const getUserInfo = await getUser(loginUserId);
  const userInfo = {
    user_id: getUserInfo.user_id,
    user_name: getUserInfo.user_name,
    department: {
      dep_id: getUserInfo.department.dep_id,
      dep_name: getUserInfo.department.dep_name,
    },
    role: {
      role_id: getUserInfo.role.role_id,
      role_name: getUserInfo.role.role_name,
    },
    email: getUserInfo.email,
    work_phone_number: getUserInfo.work_phone_number,
    phone_number: getUserInfo.phone_number,
    is_car: getUserInfo.is_car,
    is_admin: getUserInfo.is_admin,
    user_note: getUserInfo.user_note,
    is_deleted: getUserInfo.is_deleted,
  };
  return userInfo;
}

export default function UserInfoPage() {
  // const loginUserId = useRecoilValue(loginUserState);
  const loggingUserInfo = useRecoilValue(loginUserInfoState);
  const [isEdit, setIsEdit] = useRecoilState(userEditState);
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalPwdOpenState);
  const handleError = useErrorHandler();
  const [openDialog, setOpenDialog] = useRecoilState(openDialogState);
  const [dialogContent, setDialogContent] = useRecoilState(dialogContentState);
  // const [mode, setMode] = useRecoilState(modeState);
  const [userInfoData, setUserInfoData] = useState<UserInfo>({
    user_id: "",
    user_name: "",
    department: {
      dep_id: -1,
      dep_name: "",
    },
    role: {
      role_id: -1,
      role_name: "",
    },
    email: "",
    work_phone_number: "",
    phone_number: "",
    is_car: false,
    is_admin: false,
    user_note: "",
    is_deleted: false,
  });
  const [partList, setPartList] = useState<Department[]>([]);
  const [roleList, setRoleList] = useState<Role[]>([]);

  const [updateUserInfo, setUpdateUserInfo] = useState<UserInfo>({
    user_id: userInfoData.user_id, // user_id 추가
    user_name: userInfoData.user_name, // user_name 추가
    department: {
      dep_id: userInfoData.department.dep_id,
      dep_name: userInfoData.department.dep_name,
    },
    role: {
      role_id: userInfoData.role.role_id,
      role_name: userInfoData.role.role_name,
    },
    email: userInfoData.email,
    work_phone_number: userInfoData.work_phone_number,
    phone_number: userInfoData.phone_number,
    is_car: userInfoData.is_car, // is_car 추가
    is_admin: userInfoData.is_admin,
    user_note: userInfoData.user_note,
    is_deleted: userInfoData.is_deleted, // is_deleted 추가
  });

  useEffect(() => {
    setIsEdit(false);
    async function callLoginUserInfo() {
      if (loggingUserInfo) {
        try {
          const { data } = await getUser(loggingUserInfo.user_id);
          setUserInfoData(data);
        } catch (error) {
          handleError(error);
        }
      } else {
        console.error("No login user ID found.");
      }
    }
    callLoginUserInfo();
  }, [setUserInfoData]);

  const onEdit = async () => {
    setIsEdit(true);
    // setMode("edit");
    const partsData = await getPartList();
    console.log(partsData.data, "partsData");
    setPartList(partsData.data);
    const rolesData = await getRoleList();
    console.log(rolesData.data, "rolesData");
    setRoleList(rolesData.data);
    setUpdateUserInfo({
      user_id: userInfoData.user_id,
      user_name: userInfoData.user_name,
      department: {
        dep_id: userInfoData.department.dep_id,
        dep_name: userInfoData.department.dep_name,
      },
      role: {
        role_id: userInfoData.role.role_id,
        role_name: userInfoData.role.role_name,
      },
      email: userInfoData.email,
      work_phone_number: userInfoData.work_phone_number,
      phone_number: userInfoData.phone_number,
      is_car: userInfoData.is_car,
      is_admin: userInfoData.is_admin,
      user_note: userInfoData.user_note,
      is_deleted: userInfoData.is_deleted,
    });
  };

  const onCancel = () => {
    setDialogContent(
      "변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
    );
    setIsEdit(false);
  };

  const onSave = async () => {
    try {
      setIsEdit(false);
      await updateUserBySelf(userInfoData.user_id, updateUserInfo); // 수정된 정보 보내기
      console.log(userInfoData, updateUserInfo, "updateUserInfo");

      setUserInfoData((prev) => ({
        ...prev,
        ...updateUserInfo,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const id = useId();

  const handlePhoneNumberChange = (
    phoneNumber: string,
    phoneNumberType: "tel" | "phone" | "fax"
  ) => {
    setUpdateUserInfo((prevData) => ({
      ...prevData,
      [phoneNumberType === "tel" ? "work_phone_number" : "phone_number"]:
        phoneNumber !== null ? phoneNumber : "",
    }));
  };

  const handlePart = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);
    console.log(value);
    setUpdateUserInfo((prevData) => ({
      ...prevData,
      dep_id: value,
    }));
  };

  const handleRole = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);
    setUpdateUserInfo((prevData) => ({
      ...prevData,
      role_id: value,
    }));
  };

  const handelInputEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUpdateUserInfo((prevData) => ({
      ...prevData,
      email: value,
    }));
  };

  const clickPwdChange = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Settings</div>
          <div className="sub_menu">계정 설정</div>
        </div>

        <div id={"main_card"} style={{ padding: "50px 100px" }}>
          <div className="user_info_data">
            <div>
              <label htmlFor={`${id}-사번`}>사번</label>
              <p id={`${id}-사번`}>{userInfoData.user_id}</p>
            </div>
            <div>
              {isEdit !== true ? (
                <>
                  <label htmlFor={`${id}-부서`}>부서</label>
                  <p id={`${id}-부서`}>{userInfoData.department.dep_name}</p>
                </>
              ) : (
                <SelectInput
                  label="부서"
                  id={`${id}-부서`}
                  options={partList.map((part) => ({
                    id: part.dep_id,
                    name: part.dep_name,
                  }))}
                  value={updateUserInfo.department.dep_id}
                  onChange={handlePart}
                />
              )}
            </div>
            <div>
              {isEdit !== true ? (
                <>
                  <label htmlFor={`${id}-직책`}>직책</label>
                  <p id={`${id}-직책`}>{userInfoData.role.role_name}</p>
                </>
              ) : (
                <SelectInput
                  label="직책"
                  id={`${id}-직책`}
                  options={roleList.map((role) => ({
                    id: role.role_id,
                    name: role.role_name,
                  }))}
                  value={updateUserInfo.role.role_id}
                  onChange={handleRole}
                />
              )}
            </div>
            <div>
              <label htmlFor={`${id}-성명`}>성명</label>
              <p id={`${id}-성명`}>{userInfoData.user_name}</p>
            </div>
            <div>
              <label htmlFor={`${id}-이메일`}>이메일</label>
              {isEdit !== true ? (
                userInfoData.email ? (
                  <p id={`${id}-이메일`}>{userInfoData.email}</p>
                ) : (
                  <p>-</p>
                )
              ) : (
                <input
                  type="email"
                  id={`${id}-이메일`}
                  name="email"
                  value={userInfoData.email}
                  onChange={handelInputEmail}
                />
              )}
            </div>
            <div>
              <label htmlFor={`${id}-휴대폰번호`}>연락처(회사)</label>
              {isEdit !== true ? (
                userInfoData.work_phone_number ? (
                  <p id={`${id}-휴대폰번호`}>
                    {userInfoData.work_phone_number}
                  </p>
                ) : (
                  <p>-</p>
                )
              ) : (
                <div className="user_phone_form">
                  <PhoneForm
                    phoneNumberType="phone"
                    phoneNumberData={userInfoData.work_phone_number}
                    onPhoneNumberChange={handlePhoneNumberChange}
                  />
                </div>
              )}
            </div>
            <div>
              <label htmlFor={`${id}-휴대폰번호`}>연락처(개인)</label>
              {isEdit !== true ? (
                userInfoData.phone_number ? (
                  <p id={`${id}-휴대폰번호`}>{userInfoData.phone_number}</p>
                ) : (
                  <p>-</p>
                )
              ) : (
                <div className="user_phone_form">
                  <PhoneForm
                    phoneNumberType="phone"
                    phoneNumberData={userInfoData.phone_number}
                    onPhoneNumberChange={handlePhoneNumberChange}
                  />
                </div>
              )}
            </div>
            <div>
              <label>비밀번호</label>
              {isEdit !== true ? (
                <button
                  className="write_button"
                  style={{
                    marginLeft: "10px",
                    opacity: 0.5,
                  }}
                  onClick={clickPwdChange}
                  disabled
                >
                  비밀번호 변경
                </button>
              ) : (
                <button
                  className="write_button"
                  style={{ marginLeft: "10px" }}
                  onClick={clickPwdChange}
                >
                  비밀번호 변경
                </button>
              )}
            </div>
            <ModalPassword />
            <div>
              <label htmlFor="isAdmin">관리자 계정</label>
              <div>
                <input
                  type="checkbox"
                  id="isAdmin"
                  style={{ marginLeft: "10px" }}
                  checked={userInfoData.is_admin}
                />
                {isEdit === true ? (
                  <span>* 계정설정 페이징 수정 불가</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="user_info_bottom">
              {isEdit !== true ? (
                <>
                  <button className="write_button" onClick={onEdit}>
                    <EditIcon style={{ marginRight: "4px" }} />
                    수정
                  </button>
                </>
              ) : (
                <>
                  <button className="cancel_button" onClick={onCancel}>
                    <CancelIcon style={{ marginRight: "4px" }} />
                    취소
                  </button>
                  <button className="write_button" onClick={onSave}>
                    <SaveIcon style={{ marginRight: "4px" }} />
                    저장
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
