import { useSetRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";
import { CancelButton, SaveButton, successSave } from "components/Common";
import { ReactComponent as UserIdIcon } from "assets/writeIcon/userid.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/name.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as RoleIcon } from "assets/writeIcon/role.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as MailIcon } from "assets/writeIcon/mail.svg";
import { ReactComponent as PasswordIcon } from "assets/writeIcon/password.svg";
import { ReactComponent as AdminIcon } from "assets/writeIcon/admin.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { useEffect, useId, useState } from "react";
import {
  Department,
  getPartList,
  getRoleList,
  Role,
  updateUserByAdmin,
  User,
} from "api/user";
import { userListState } from "recoil/stateStore";
import { DialogText } from "components/DialogText";
import { SelectInput } from "components/SelectInput";
import PhoneForm from "components/writeForm/PhoneForm";
import { useErrorHandler } from "components/ErrorHandler";
import { Checkbox } from "@mui/material";

interface EditUserFormProps {
  userInfo: User;
  setUserInfo: Function;
  // setIsEdit: (state: boolean) => void;
}
const EditUserForm: React.FC<EditUserFormProps> = ({
  userInfo,
  setUserInfo,
  // setIsEdit,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [partList, setPartList] = useState<Department[]>([]);
  const [roleList, setRoleList] = useState<Role[]>([]);
  const [partId, setPartId] = useState<number>(-1);
  const [roleId, setRoleId] = useState<number>(-1);
  const [name, setName] = useState<string>(userInfo.user_name);
  const [email, setEmail] = useState<string>(userInfo.email);
  const [compPhone, setCompPhone] = useState<string>(
    userInfo.work_phone_number
  );
  const [privatePhone, setPrivatePhone] = useState<string>(
    userInfo.phone_number || "--"
  );
  const [password, setPassword] = useState<string>("");
  const [isCar, setIsCar] = useState<boolean>(userInfo.is_car);
  const [isAdmin, setIsAdmin] = useState<boolean>(userInfo.is_admin);
  const [note, setNote] = useState<string>(userInfo.user_note);
  const setUserList = useSetRecoilState(userListState);
  const handleError = useErrorHandler();
  const setIsEdit = useSetRecoilState(isEditedState);

  const id = useId();

  useEffect(() => {
    const callPartRoleList = async () => {
      const resPart = await getPartList();
      setPartList(resPart.data);
      const resRole = await getRoleList();
      setRoleList(resRole.data);

      setPartId(userInfo.department.dep_id);
      setRoleId(userInfo.role.role_id);
    };
    callPartRoleList();
  }, [userInfo]);

  // 취소버튼
  const onCancel = () => {
    setOpenDialog(true);
  };

  // 저장방법
  const handleSubmit = async () => {
    // onSubmit 함수를 async로 선언

    console.log("수정 저장");
    try {
      const data = {
        dep_id: partId,
        role_id: roleId,
        user_name: name,
        email,
        work_phone_number: compPhone,
        phone_number: privatePhone,
        password,
        is_car: isCar,
        is_admin: isAdmin,
        user_note: note,
      };

      const updatedUser = await updateUserByAdmin(userInfo.user_id, data);
      setUserList((prev) =>
        prev.map((user) =>
          user.user_id === updatedUser.user_id ? updatedUser : user
        )
      );

      setUserInfo(updatedUser);
      console.log(userInfo.user_id, data, updatedUser, "수정 데이터");

      successSave();
      setIsEdit(false);
    } catch (error) {
      handleError(error); // 오류 처리 함수 호출
    }
  };

  return (
    <div className="write_list_wrapper">
      <div className="write_content">
        <div>
          <div className="div_icon">
            <UserIdIcon style={{ marginRight: "4px" }} />
            사번
          </div>
          <span>{userInfo.user_id}</span>
        </div>
        <div>
          <SelectInput
            label="부서"
            labelIcon={<PartIcon style={{ marginRight: "4px" }} />}
            id={`${id}-부서`}
            options={partList.map((part) => ({
              id: part.dep_id,
              name: part.dep_name,
            }))}
            value={partId}
            onChange={({ target }) => setPartId(parseInt(target.value))}
          />
        </div>
        <div className="div_icon">
          <SelectInput
            label="직책"
            labelIcon={<RoleIcon style={{ marginRight: "4px" }} />}
            id={`${id}-직책`}
            options={roleList.map((role) => ({
              id: role.role_id,
              name: role.role_name,
            }))}
            value={roleId}
            onChange={({ target }) => {
              setRoleId(parseInt(target.value));
            }}
          />
        </div>
        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            성명
          </div>
          <input
            type="text"
            name="user_name"
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
        </div>
        <div>
          <div className="div_icon">
            <MailIcon style={{ marginRight: "4px" }} />
            이메일
          </div>
          <input
            type="text"
            name="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            required
          />
        </div>
        <div className="input_phone_number">
          <div className="div_icon">
            <PhoneIcon style={{ marginRight: "4px" }} />
            연락처(업무)
          </div>
          <PhoneForm
            phoneNumberType="phone"
            phoneNumberData={compPhone}
            onPhoneNumberChange={setCompPhone}
          />
        </div>
        <div className="input_phone_number">
          <div className="div_icon">
            <PhoneIcon style={{ marginRight: "4px" }} />
            연락처(개인)
          </div>
          <PhoneForm
            phoneNumberType="phone"
            phoneNumberData={privatePhone}
            onPhoneNumberChange={setPrivatePhone}
          />
        </div>
        <div className="password_content">
          <div className="div_icon">
            <PasswordIcon style={{ marginRight: "4px" }} />
            비밀번호
            <span style={{ color: "red" }}>&nbsp;*</span>
          </div>
          <input
            type="password"
            name="password"
            placeholder="*****"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>
        {/* <div className="admin_box">
          <div className="div_icon">
            <Car
              style={{
                marginRight: "4px",
                fill: "black",
              }}
            />
            차량 유무
          </div>
          <div>
            <Checkbox
              sx={{
                borderColor: "",
              }}
              checked={isCar ? true : false}
              inputProps={{ "aria-label": "controlled" }}
              onChange={({ target }) => setIsCar(target.checked)}
            />
          </div>
        </div> */}
        <div className="admin_box">
          <div className="div_icon">
            <AdminIcon
              style={{
                marginRight: "4px",
                fill: "black",
                fillOpacity: "0.5",
              }}
            />
            관리자 계정
          </div>
          {/* <input
              type="checkbox"
              className="check_box"
              checked={isAdmin}
              onChange={({ target }) => setIsAdmin(target.checked)}
              /> */}
          <div>
            <Checkbox
              sx={{
                borderColor: "",
              }}
              checked={isAdmin}
              onChange={(e) => {
                console.log("Checkbox checked:", e.target.checked);
                setIsAdmin(e.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <NoteIcon style={{ marginRight: "4px" }} /> 비고
          </div>
          <textarea
            style={{ height: "140px", marginTop: "4px", color: "#000" }}
            name="work_content"
            value={note}
            onChange={({ target }) => setNote(target.value)}
          />
        </div>
      </div>
      <div className="write_bottom">
        <DialogText
          open={openDialog}
          content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
          onCancel={() => setOpenDialog(false)}
          onClose={() => setIsEdit(false)}
        />
        <CancelButton onClickHandler={onCancel} />
        <SaveButton onClickHandler={handleSubmit} />
      </div>
    </div>
  );
};
export default EditUserForm;
