import { getCompany } from "api/company";
import { Member, getCompanyMembers } from "api/member";
import { ResponseYearPlan, updateAnnualPlan } from "api/yearPlan";
import { useErrorHandler } from "components/ErrorHandler";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { autoSelectCompIdState, isEditedState } from "recoil/stateStore";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MoenyIcon } from "assets/writeIcon/half-money.svg";
import { ReactComponent as RemoveIcon } from "assets/remove.svg";
import { ReactComponent as AddIcon } from "assets/add.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import PlanDate from "components/PlanDate";
import AutoCompleteCompany from "components/AutoCompleteCompany";
import { CancelButton, SaveButton } from "components/Common";
import DialogText from "components/DialogText";

interface EditYearFormProps {
  yearPlanData: ResponseYearPlan;
  setYearPlanData: Function;
  // setIsEdit: (state: boolean) => void;
  planId: number;
}

const EditYearForm: React.FC<EditYearFormProps> = ({
  yearPlanData,
  setYearPlanData,
  // setIsEdit,
  planId,
}) => {
  const handleError = useErrorHandler();
  const [year, setYear] = useState<string>(yearPlanData.aplan_year);
  const loginUserName = localStorage.getItem("user_name");
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [openDialog, setOpenDialog] = useState(false);
  const [compId, setCompId] = useState<number | null>(
    yearPlanData.company?.comp_id ?? -1
  );
  const [quarter1, setQuarter1] = useState<number>(yearPlanData.first_quarter);
  const [quarter2, setQuarter2] = useState<number>(yearPlanData.second_quarter);
  const [quarter3, setQuarter3] = useState<number>(yearPlanData.third_quarter);
  const [quarter4, setQuarter4] = useState<number>(yearPlanData.fourth_quarter);
  const [compProgress, setCompProgress] = useState<string>(
    yearPlanData.comp_progress
  );
  const [salesPlan, setSalesPlan] = useState<string>(yearPlanData.sales_plan);
  const [memberIds, setMemberIds] = useState<number[]>(
    yearPlanData.comp_members.map((member) => member.member_id)
  );
  const [regionItem, setRegionItem] = useState<string>(
    yearPlanData.company?.comp_sido_nm ?? ""
  );
  const [sectors, setSectors] = useState<string>(
    yearPlanData.company?.sectors ?? ""
  );
  const [members, setMembers] = useState<Member[]>(yearPlanData.comp_members);
  const [autoSelectCompId, setAutoSelectedCompId] = useRecoilState(
    autoSelectCompIdState
  );
  const [selectCount, setSelectCount] = useState<number>(yearPlanData.comp_members.length);
  const setIsEdit = useSetRecoilState(isEditedState);

  const onCancel = () => {
    setOpenDialog(true);
  };

  const setDate = (year: string, month: string) => {
    setYear(year);
  };

  useEffect(() => {
    setCompId(autoSelectCompId);
    if (autoSelectCompId) {
      const fetchData = async () => {
        try {
          const companyInfo = await getCompany(autoSelectCompId);
          setRegionItem(
            companyInfo.data.comp_sido_nm + " " + companyInfo.data.comp_sgg_nm
          );
          setSectors(companyInfo.data.sectors);
          const response = await getCompanyMembers(autoSelectCompId);
          const memberData = response.data.content;
          console.log(memberData.member_list, "memberList")
          setMembers(memberData.member_list);
        } catch (error) {
          console.log("no members");
        }
      };
      fetchData();
    }
  }, [autoSelectCompId]);

  useEffect(() => {
    setAutoSelectedCompId(compId);
    const callMambers = async () => {
      try {
        const response = await getCompanyMembers(compId);
        const memberData = response.data.content;
        setMembers(memberData.member_list);
        console.log(memberData.member_list, memberIds, "member_list");
      } catch (error) {
        console.log("no members");
      }
    };
    callMambers();
  }, []);

  const handleSelectMember = (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const selectedMemberId = parseInt(e.target.value);

    if (memberIds.includes(selectedMemberId)) {
      alert("이미 해당 담당자가 선택되어있습니다.");
    } else {
      const updatedMemberIds = [...memberIds];
      updatedMemberIds[index] = selectedMemberId;
      const trimmedMemberIds = updatedMemberIds.slice(0, selectCount);
      setMemberIds(trimmedMemberIds);
      console.log(updatedMemberIds, "updatedMemberIds");
    }
  };

  const addSelect = () => {
    setSelectCount(selectCount + 1);
  };

  const removeSelect = (index: number) => {
    const filteredMemberIds = memberIds.filter((_, idx) => idx !== index);
    setSelectCount(selectCount - 1);
    setMemberIds(filteredMemberIds);
    console.log(filteredMemberIds, "filteredMemberIds")
  };

  const formatCurrency = (value: number | null) => {
    if (value === null) return "";
    return value.toLocaleString("ko-KR");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const value = e.target.value.replace(/,/g, "")
    const numericValue = parseInt(value, 10); // 10진수 변환 
    if (!isNaN(numericValue)) {
      if (type === "Q1") {
        setQuarter1(numericValue);
      } else if (type === "Q2") {
        setQuarter2(numericValue);
      } else if (type === "Q3") {
        setQuarter3(numericValue);
      } else if (type === "Q4") {
        setQuarter4(numericValue);
      }
    } else {
      if (type === "Q1") {
        setQuarter1(0);
      } else if (type === "Q2") {
        setQuarter2(0);
      } else if (type === "Q3") {
        setQuarter3(0);
      } else if (type === "Q4") {
        setQuarter4(0);
      }
    }
  };

  // 저장 버튼
  const handleSubmit = async () => {
    if (
      year === null ||
      compId === null ||
      compProgress === "" ||
      salesPlan === "" ||
      memberIds.length === 0 || memberIds[0] === -1
    ) {
      console.log(compId)
      alert("필수값이 입력되지 않았습니다. ");
    } else {
      try {
        const data = {
          aplan_id: planId,
          user_id: loginUserId,
          comp_id: compId,
          aplan_year: year,
          first_quarter: quarter1,
          second_quarter: quarter2,
          third_quarter: quarter3,
          fourth_quarter: quarter4,
          comp_progress: compProgress,
          sales_plan: salesPlan,
          member_ids: memberIds.filter((id) => id !== -1),
          aplan_note: "",
          first_half: quarter1 + quarter2,
          second_half: quarter3 + quarter4,
          comp_members: members,
        };
        const updateYearPlan = await updateAnnualPlan(planId, data);
        setYearPlanData(updateYearPlan);
        setIsEdit(false); // 편집 모드를 종료
      } catch (error) {
        handleError(error); // 오류 처리
      }
    }
  };

  return (
    <div>
      <div className="write_list_wrapper">
        <div className="work_option">
          {/* 연도 설정 */}
          <div>
            <div className="div_icon" style={{ marginRight: "15px" }}>
              <CalendarIcon style={{ marginRight: "4px" }} />
              연도
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <div className="mplan_date" style={{ width: "60%" }}>
              <PlanDate setDate={setDate} selectedYDate={year} selectedMDate={"No"} />
            </div>
          </div>

          {/* 담당자 표시 */}
          <div>
            <div className="div_icon">
              <UserIcon style={{ marginRight: "4px" }} /> 담당자
            </div>
            <span>
              {loginUserName} ({loginUserId})
            </span>
          </div>

          {/* 업체명 및 지역/업종 */}
          <div>
            <div className="div_icon">
              <CompanyIcon style={{ marginRight: "4px" }} /> 업체명
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <AutoCompleteCompany />
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} /> 지역
            </div>
            <span>{regionItem}</span>
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} /> 업종
            </div>
            <span>{sectors}</span>
          </div>

          {/* 분기별 매출액 */}
          <div>분기별 매출액 (단위: 만원)</div>
          {[quarter1, quarter2, quarter3, quarter4].map((quarter, index) => {
            const quarterTypes = ["Q1", "Q2", "Q3", "Q4"]; //인덱스에 따라 문자열 매핑
            const quarterType = quarterTypes[index];

            return (
              <div key={index}>
                <div className="div_icon">
                  <MoenyIcon style={{ marginRight: "4px" }} />
                  {index + 1}분기
                </div>
                <input
                  type="text"
                  value={formatCurrency(quarter)}
                  placeholder="0"
                  onChange={(e) => handleInputChange(e, quarterType)}
                />
              </div>
            );
          })}

          {/* 업체별 진행 현황 */}
          <div className="textarea_wrapper">
            <div className="div_icon">
              업체별 진행현황
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <textarea
              value={compProgress}
              name="plan"
              style={{ color: "#000", height: "100px" }}
              onChange={({ target }) => setCompProgress(target.value)}
            />
          </div>

          {/* 영업계획 */}
          <div className="textarea_wrapper">
            <div className="div_icon" style={{ marginTop: "16px" }}>
              영업계획
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <textarea
              value={salesPlan}
              style={{ color: "#000", height: "100px" }}
              onChange={({ target }) => setSalesPlan(target.value)}
            />
          </div>
        </div>

        {/* 주요 담당자 선택 */}
        <div>
          <div className="members_wrapper">
            <span style={{ paddingTop: "5px", color: "rgb(0, 0, 0, 0.6)" }}>
              주요 담당자
              <span style={{ color: "red" }}>&nbsp;*</span>
            </span>
            <span style={{ paddingTop: "5px" }}>Key</span>
            <div className="select_members">
              {Array.from({ length: selectCount }, (_, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {selectCount > 1 && (
                    <div
                      style={{
                        width: "25px",
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginRight: "5px",
                      }}
                    >
                      <RemoveIcon
                        className="removeBtn"
                        onClick={() => removeSelect(index)}
                      />
                    </div>
                  )}
                  <select
                    disabled={compId === null || compId === -1}
                    style={{ width: "150px" }}
                    value={memberIds[index] ?? -1}
                    onChange={(e) => handleSelectMember(e, index)}
                  >
                    {compId === null || compId === -1 ? (
                      <option value="-1">고객사를 먼저 선택하세요</option>
                    ) : (
                      <>
                        <option value="-1">선택하세요</option>
                        {members.map((member) => (
                          <option
                            key={member.member_id}
                            value={member.member_id}
                          >
                            {member.member_name} {member.member_role}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
              ))}
              <button
                className="plusBtn"
                onClick={addSelect}
                style={{
                  width: "150px",
                  height: "25px",
                  lineHeight: "25px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                disabled={compId === null || compId === -1}
              >
                <AddIcon style={{ width: "20px" }} />
              </button>
            </div>
          </div>
        </div>

        {/* 저장 및 취소 버튼 */}
        <div className="write_bottom">
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={() => setIsEdit(false)}
          />
          <CancelButton onClickHandler={onCancel} />
          <SaveButton onClickHandler={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default EditYearForm;
