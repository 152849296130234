import axios, { Axios } from "axios";
import { client, getErrorMessage } from "./client";
import { SimpleCompany } from "./company";
import { Member } from "./member";

// 상담일지 등록 응답
export interface CounselingType {
  cons_id: number;
  user_id: string;
  user_name: string;
  cons_date: Date;
  company: SimpleCompany;
  member: Member;
  project: string;
  item: string;
  amount: number;
  price: number; //상담 예상액
  sum: number;
  result: string;
  cons_note: string;
}

// 상담일지 등록 요청
export interface NewCounseling {
  cons_date: Date;
  user_id: string;
  user_name: string;
  comp_id: number | null;
  member_id: number | null;
  project: string;
  item: string;
  amount: number;
  price: number;
  result: string;
  cons_note: string;
}

// 상담일지 전체 조회
export interface CounselingList {
  total_count: number;
  content: CounselingType[];
}

// 상담일지 목록
export const getCounselingList = async (
  user_id: string,
  start_date: string,
  end_date: string
) => {
  try {
    // const token = localStorage.getItem("token");
    const response = await client.get("/consultings", {
      headers: {
        // Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
      params: { user_id, start_date, end_date },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 상담일지 상세보기
export const getConsulting = async (id: number) => {
  try {
    // const token = localStorage.getItem("token");
    const response = await client.get(`/consulting/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 상담일지 등록
export const createCons = async (newCounseling: NewCounseling) => {
  try {
    console.log(newCounseling);
    const response = await client.post("/consultings", newCounseling);
    console.log(response);
    return response;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 상담일지 수정
export const updateCons = async (
  cons_id: number,
  newCounseling: NewCounseling
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.patch(
      `/consulting/${cons_id}`,
      newCounseling,
      {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
        },
      }
    );
    console.log(response);
    return response.data.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};

// 상담일지 삭제
export const deleteCons = async (cons_id: number) => {
  try {
    const token = localStorage.getItem("token");
    const response = await client.delete(`/consulting/${cons_id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(getErrorMessage(error));
  }
};
