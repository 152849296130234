import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  checkSaveIdState,
  filterUserState,
  isAccordionExpendedState,
  loginUserInfoState,
  selectedIndexState,
  serverIPState,
  userState,
} from "recoil/stateStore";
import "styles/LoginStyle.css";
import { ReactComponent as SettingIcon } from "../assets/setting.svg";
import LogoImg from "assets/logo.png";
import "styles/LoginStyle.css";
import { useErrorHandler } from "components/ErrorHandler";
import { UserTypes, loginUser } from "api/user";
import { client } from "api/client";

export default function LoginForm() {
  const navigate = useNavigate();
  const [isButtonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [user, setUser] = useRecoilState(userState);
  // const [serverIP, setServerIP] = useRecoilState(serverIPState);
  // const [inputServe, setInputServe] = useRecoilState(serverIPState);
  // const [showInput, setShowInput] = useState(false);

  const [checkSaveId, setCheckSaveId] = useRecoilState(checkSaveIdState);
  const handleError = useErrorHandler();
  const setLoginUserInfo = useSetRecoilState(loginUserInfoState);
  const setSelectedIndex = useSetRecoilState(selectedIndexState);
  const setExpanded = useSetRecoilState(isAccordionExpendedState);
  const setFilterUser = useSetRecoilState(filterUserState);

  useEffect(() => {
    // const serverIP = localStorage.getItem("serverIP");
    // if (serverIP) {
    //   setServerIP(serverIP);
    // }
    const savedId = window.localStorage.getItem("saveId");
    localStorage.removeItem("serverIP");
    if (savedId) {
      setCheckSaveId(true);
      setUser((prevUser) => ({
        ...prevUser,
        user_id: savedId,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setButtonEnabled(!!user.user_id && !!user.password);
  }, [user.user_id, user.password]);

  const activeEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  const handleClick = async () => {
    try {
      const userCredentials: UserTypes = {
        user_id: user.user_id,
        password: user.password,
      };
      console.log("cc");
      const loggedInUser = await loginUser(userCredentials);
      console.log(loggedInUser);
      // alert("로그인 성공!");

      if (!loggedInUser) {
        return;
      }
      setLoginUserInfo({
        user_id: loggedInUser.data.data.user_id,
        user_name: loggedInUser.data.data.user_name,
        is_admin: loggedInUser.data.data.is_admin,
        user_role: loggedInUser.data.data.user_role,
        token: loggedInUser.data.data.token,
      });
      const getUserId = localStorage.getItem("user_id");
      // if (getUserId) {
      //   setLoginUserId(getUserId);
      // }
      // const isAdmin = localStorage.getItem("is_admin");
      // if (isAdmin) {
      //   console.log(isAdmin);
      //   setCheckAdmin(isAdmin);
      // }

      if (getUserId) {
        localStorage.setItem("saveId", getUserId);
      } else {
        localStorage.removeItem("saveId");
      }

      if (checkSaveId) {
        localStorage.setItem("saveId", user.user_id.toString());
      } else {
        localStorage.removeItem("saveId");
      }
      setSelectedIndex(`panel1-0`);
      setExpanded("panel1");
      setFilterUser("");
      navigate("/");
      // setOpen(false);
      // try {
      //     const { totalItems, extractedData } = await fetchWorkLogDatas(
      //         searchWorkOption
      //     );
      //     setWorkTableData(extractedData);
      //     setTotalItems(totalItems);
      // } catch (error) {
      //     setWorkTableData([]);
      //     setTotalItems(0);
      //     console.log("test");
      // }
    } catch (error) {
      handleError(error);
    }
  };

  // const handleIPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputData = event.target.value;
  //   setServerIP(inputData);
  //   console.log(inputData);
  //   setInputServe(inputData);
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "userId") {
      setUser((prevUser) => ({
        ...prevUser,
        user_id: value,
      }));
    } else if (name === "password") {
      setUser((prevUser) => ({
        ...prevUser,
        password: value,
      }));
    }
  };

  // const clickIPRegister = () => {
  //   localStorage.setItem("serverIP", inputServe);
  //   client.defaults.baseURL = `${inputServe}/api`;
  //   setShowInput(false);
  //   alert("성공적으로 IP 주소가 설정되었습니다.");
  // };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckSaveId((cur) => !cur);
  };

  // const handleShowInput = () => {
  //   setShowInput((prevShowInput) => !prevShowInput);
  // };

  return (
    <>
      <div className="wrap_content">
        <div className="login_top">
          <img src={LogoImg} alt="CNI Logo" />
          <span>업무관리 시스템입니다.</span>
          <span>
            <span style={{ fontWeight: 700 }}>로그인</span>을 위해 아래 정보를
            입력해주세요.
          </span>
        </div>
        <div className="login_form">
          <form method="post">
            <div id="form_id">
              <input
                type="text"
                name="userId"
                placeholder="아이디 (사번)"
                value={user.user_id || ""}
                autoFocus
                onChange={handleChange}
                onKeyDown={(e) => activeEnter(e)}
              ></input>
            </div>
            <div id="form_pwd">
              <input
                type="password"
                name="password"
                placeholder="비밀번호"
                autoFocus
                onChange={handleChange}
                onKeyDown={(e) => activeEnter(e)}
              ></input>
            </div>
            <button
              type="button"
              onClick={handleClick}
              disabled={!isButtonEnabled}
            >
              로그인
            </button>
          </form>
        </div>
        <div className="login_bottom">
          <div className="id_save">
            <input
              type="checkbox"
              id="check"
              onChange={handleCheckboxChange}
              checked={checkSaveId}
            ></input>
            <label htmlFor="check">아이디 저장</label>
          </div>
          {/* <div className="input_ip_address">
            <button type="button" onClick={handleShowInput}>
              <SettingIcon viewBox="-3.5 -3 30 30" />
            </button>
            {showInput && (
              <div className="ip_set">
                <input
                  type="text"
                  name="serverIP"
                  placeholder={serverIP}
                  value={serverIP}
                  onChange={handleIPChange}
                />
                <button
                  style={{ cursor: "pointer" }}
                  className="server_set_btn"
                  onClick={clickIPRegister}
                >
                  설정
                </button>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}
