import { useEffect, useState } from "react";
import WorkLogDrawer from "components/WorkLogDrawer";
import { ResponseWorkLog, getWorkLog } from "api/workLog";
import EditWorkForm from "../editForm/EditWorkForm";
import ViewWorkForm from "components/viewForm/ViewWorkForm";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";
import { serializeWorkLog } from "util/serializer";
interface FormDrawerProps {
  open: boolean;
  onClose: (event: any) => void;
  workId: number;
  setUpdatedData: Function;
  setDeletedId: Function;
}

const ViewWorkDrawer: React.FC<FormDrawerProps> = ({
  open,
  onClose,
  workId,
  setUpdatedData,
  setDeletedId,
}) => {
  // const [isEdit, setIsEdit] = useState(false);
  const [isEdit, setIsEdit] = useRecoilState(isEditedState);
  const [workLogData, setWorkLogData] = useState<ResponseWorkLog>(
    {} as ResponseWorkLog
  );

  useEffect(() => {
    const callWorkLog = async () => {
      const workData = await getWorkLog(workId);
      console.log(workData.data, "workData");
      setWorkLogData(workData.data);
      setUpdatedData(workData.data);
      console.log(workData.data);
    };
    if (workId !== -1 && !isEdit) {
      callWorkLog();
    }
  }, [workId, isEdit]);

  return (
    <WorkLogDrawer open={open} onClose={onClose}>
      {isEdit ? (
        <div className="write_head">업무일지 수정</div>
      ) : (
        <div className="write_head">업무일지 상세보기</div>
      )}
      {isEdit ? (
        <EditWorkForm
          workLogData={workLogData}
          setWorkLogData={setWorkLogData}
          // setIsEdit={setIsEdit}
        />
      ) : (
        <ViewWorkForm
          workLogData={workLogData}
          // setIsEdit={setIsEdit}
          onClose={onClose}
          setDeletedId={setDeletedId}
        />
      )}
    </WorkLogDrawer>
  );
};

export default ViewWorkDrawer;
