import { ResponseNewMember, patchMember } from "api/member";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg"
import { ReactComponent as MemberIcon } from "assets/writeIcon/member.svg"
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg"
import { ReactComponent as RoleIcon } from "assets/writeIcon/role.svg"
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg"
import { ReactComponent as MailIcon } from "assets/writeIcon/mail.svg"
import PhoneForm from "components/writeForm/PhoneForm";
import { useState } from "react";
import DialogText from "components/DialogText";
import { CancelButton, SaveButton, successSave } from "components/Common";
import { useSetRecoilState } from "recoil";
import { dialogContentState, isEditedState } from "recoil/stateStore";
import { useErrorHandler } from "components/ErrorHandler";

interface EditMemberFormProps {
    memberData: ResponseNewMember;
    setMemberData: Function;
}

const EditMemberForm: React.FC<EditMemberFormProps> = ({
    memberData,
    setMemberData,
}) => {
    const [memberName, setMemberName] = useState<string>(memberData.member_name);
    const [department, setDepartment] = useState<string>(memberData.member_department);
    const [role, setRole] = useState<string>(memberData.member_role);
    const [memberPhone, setMemberPhone] = useState<string>(memberData.member_phone);
    const [email, setEmail] = useState<string>(memberData.member_email);
    const [note, setNote] = useState<string>(memberData.member_note);
    const [openDialog, setOpenDialog] = useState(false);
    const handleError = useErrorHandler();
    const setIsEdit = useSetRecoilState(isEditedState);

    const handlePhoneNumberChange = (
        phoneNumber: string
    ) => {
        setMemberPhone(phoneNumber)
    }

    // 취소버튼
    const onCancel = () => {
        setOpenDialog(true);
    };

    const handleSubmit = async () => {
        console.log("수정 저장");
        if (
            memberName === "" ||
            role === "" ||
            memberPhone === ""
        ) {
            alert("필수값이 입력되지 않았습니다. ");
        } else {
            try {
                const data = {
                    member_name: memberName,
                    member_department: department,
                    member_role: role,
                    member_phone: memberPhone,
                    member_email: email,
                    member_note: note,
                    is_deleted: false,
                };
                const compId = memberData.company.comp_id;
                console.log(compId, "compId")
                const memberId = memberData.member_id;
                const updateMemberData = await patchMember(compId, memberId, data);
                setMemberData(updateMemberData);
                // setMemberList((prev) => [...prev, updateMemberData]);
                console.log(updateMemberData, "수정 데이터");
                // successSave();
                setIsEdit(false);
            } catch (error) {
                handleError(error); // 오류 처리 함수 호출
                console.log("edited worklog not saved")
            }
        }
    };

    return (
        <>
            <div className="write_list_wrapper" >
                <div className="user_option">
                    <div>
                        <div className="div_icon">
                            <CompanyIcon style={{ marginRight: "4px" }} />
                            업체명
                        </div>
                        <span>{memberData.company.comp_name}</span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <MemberIcon style={{ marginRight: "4px" }} />
                            성명
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </div>
                        <input
                            type="text"
                            name="member_name"
                            value={memberName}
                            onChange={({ target }) => setMemberName(target.value)}
                        />
                    </div>
                    <div>
                        <div className="div_icon">
                            <PartIcon style={{ marginRight: "4px" }} />
                            부서
                        </div>
                        <input
                            type="text"
                            name="member_department"
                            value={department}
                            onChange={({ target }) => setDepartment(target.value)}
                        />
                    </div>
                    <div>
                        <div className="div_icon">
                            <RoleIcon style={{ marginRight: "4px" }} />
                            직책
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </div>
                        <input
                            type="text"
                            name="member_role"
                            value={role}
                            onChange={({ target }) => setRole(target.value)}
                        />
                    </div>
                    <div className="input_phone_number">
                        <div className="div_icon">
                            <PhoneIcon style={{ marginRight: "4px" }} />
                            연락처
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </div>
                        <PhoneForm
                            phoneNumberType="tel"
                            phoneNumberData={memberPhone}
                            onPhoneNumberChange={handlePhoneNumberChange}
                        />

                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <div className="div_icon">
                            <MailIcon style={{ marginRight: "4px" }} />
                            이메일
                        </div>
                        <input
                            type="text"
                            name="member_email"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                        />
                    </div>
                </div>

                <div className="log_note">
                    비고
                    <textarea
                        value={note}
                        style={{ height: "140px", marginTop: "4px", color: "#000" }}
                        name="work_content"
                        onChange={({ target }) => setNote(target.value)}
                        disabled={false}
                    />
                </div>
                <div className="write_bottom">
                    <>
                        <DialogText
                            open={openDialog}
                            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
                            onCancel={() => setOpenDialog(false)}
                            onClose={() => setIsEdit(false)}
                        />
                        <CancelButton onClickHandler={onCancel} />
                        <SaveButton onClickHandler={handleSubmit} />
                    </>
                </div>
            </div>
        </>
    );
};

export default EditMemberForm