import { getUser } from "api/user";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { filterUserState } from "recoil/stateStore";

interface selectUserProps {
  selectUserId: string;
}

const SelectUserInfo = ({ selectUserId }: selectUserProps) => {
  // const filterUser = useRecoilValue(filterUserState);
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const callUserInfo = async () => {
      if (selectUserId && selectUserId !== "") {
        try {
          const { data } = await getUser(selectUserId);
          setUsername(data.user_name);
          setUserRole(data.role.role_name);
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    callUserInfo();
  }, [selectUserId]);

  return <h3>{selectUserId ? `${username} ${userRole}` : "전체"}</h3>;
};

export default SelectUserInfo;
