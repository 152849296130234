import { Box, Button, Card, Modal } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { formatNumber, textFilterParams } from "components/Common";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { filterUserState } from "recoil/stateStore";
import PlanDate from "components/PlanDate";
import SalesGrid from "components/SalesGrid";
import * as XLSX from "xlsx";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DialogText from "components/DialogText";
import { getRevenues, uploadRevenues } from "api/revenue";
import { ExcelIcon } from "icons";
import { getUserList } from "api/user";

interface RowData {
  product: string;
  user_name: string;
  comp_name: string;
  first_q: number;
  second_q: number;
  third_q: number;
  fourth_q: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  summary: number;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80%",
  bgcolor: "background.paper",
  border: "1px solid #ddd",
  boxShadow: 24,
  p: 4,
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

export default function SalesSummary() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const filterUser = useRecoilValue(filterUserState);
  const [rowData, setRowData] = useState<RowData[]>([]);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState<string>(String(currentYear));
  const [open, setOpen] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<RowData[]>([]); // 미리보기 데이터 상태 추가
  const [inputColor, setInputColor] = useState<string>("#262626"); // input 색상 상태 추가
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userList, setUserList] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>("");

  useEffect(() => {
    const fetchUserList = async () => {
      const users = await getUserList(false);
      console.log(users.data.content, "users");
      setUserList(users.data.content.map((user: any) => user.user_name));
    };
    fetchUserList();
  }, []);

  // 유저 필터링 -> 유저에 대한 고객사 매출 전부 로드
  // 전체 -> 모든 유저에 대한 요약 매출 표시
  // 요약 매출 : 한 유저의 담당 업체 매출 총합
  // 총합 : 모든 매출 월별로 총합
  // 년도에 따라 해당 데이터 불러오기

  useEffect(() => {
    const fetchRevenues = async () => {
      const data = await getRevenues(Number(year), filterUser);
      // if (data && data.length > 0) {
      setRowData(data);
      console.log(data, "data");
      // }
    };
    fetchRevenues();
  }, [year, filterUser, previewData]);

  const setDate = (year: string, month: string) => {
    setYear(year);
  };

  const [columnDefs] = useState<ColDef[]>([
    { headerName: "품목 분류", field: "product", flex: 1.2 },
    {
      headerName: "영업담당",
      field: "user_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
    },
    {
      headerName: "고객사명",
      field: "comp_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.2,
    },
    {
      headerName: "1월",
      field: "jan",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "2월",
      field: "feb",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "3월",
      field: "mar",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "1분기 요약",
      field: "first_q",
      flex: 1,
      cellClass: "quarterColor",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "4월",
      field: "apr",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "5월",
      field: "may",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "6월",
      field: "jun",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "2분기 요약",
      field: "second_q",
      flex: 1,
      cellClass: "quarterColor",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "7월",
      field: "jul",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "8월",
      field: "aug",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "9월",
      field: "sep",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "3분기 요약",
      field: "third_q",
      flex: 1,
      cellClass: "quarterColor",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "10월",
      field: "oct",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "11월",
      field: "nov",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "12월",
      field: "dec",
      flex: 1,
      cellClass: "quarterColor_odd",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "4분기 요약",
      field: "fourth_q",
      flex: 1.1,
      cellClass: "quarterColor",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
    {
      headerName: "연 매출 요약",
      field: "summary",
      flex: 1.1,
      cellClass: "halfColor",
      cellRenderer: (params: any) => {
        return formatNumber(params.value);
      },
    },
  ]);

  const isValidData = (data: RowData[]) => {
    if (data.length === 0) {
      return false;
    }
    return data.every((row) => row.product && row.user_name && row.comp_name);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    setInputColor("#1976d2");

    setIsLoading(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData: RowData[] = XLSX.utils.sheet_to_json(sheet);

      if (!isValidData(jsonData)) {
        alert("유효하지 않은 데이터가 포함되어 있습니다.");
        event.target.value = "";
        setPreviewData([]);
        setInputColor("#262626");
        return;
      }

      setPreviewData(jsonData);
      console.log(sheetName, jsonData, "jsonData");
      setIsLoading(false);
    };

    reader.onerror = () => {
      setIsLoading(false);
    };

    reader.readAsBinaryString(file);
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const handleClose = () => {
    setOpenDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenDialog(false); // 다이얼로그 닫기
    setInputColor("");
    setPreviewData([]);
    fileInputRef.current!.value = "";
    setYear(String(currentYear));
    setOpen(false); // 모달창 닫기
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUpload = async () => {
    console.log(previewData, "previewData");

    // 1. 프리뷰를 만들때 데이터를 빈값으로 가져오는 경우
    try {
      if (previewData.length === 0) {
        alert("업로드할 데이터가 없습니다.");
        return;
      }

      const dataList = previewData.map((row) => ({
        year: Number(year),
        ...row,
      }));

      // 추가적인 유효성 검사 (예: 각 필드의 값이 유효한지 확인)
      const hasInvalidValues = dataList.some(
        (row) => !row.jan || !row.feb || !row.mar // 예시: 월별 데이터가 유효한지 확인
      );

      if (hasInvalidValues) {
        alert("유효하지 않은 데이터가 포함되어 있습니다.");
        return;
      }
      const body = {
        data: dataList,
        year: Number(year),
      };

      await uploadRevenues(body);

      alert("매출 데이터가 성공적으로 업로드되었습니다.");
      setYear(String(year)); // 업로드 후 연도 설정
      setPreviewData([]);
      setInputColor("");
      fileInputRef.current!.value = "";
      setOpen(false);

      // 데이터 새로고침
      const newData = await getRevenues(Number(year), filterUser);
      console.log(newData, "newData");
      setRowData(newData);
    } catch (error) {
      console.error("업로드 실패:", error);
      alert("매출 데이터 업로드에 실패했습니다.");
      setOpen(false);
    }
  };

  const filteredRowData = selectedUser
    ? rowData.filter((row) => row.user_name === selectedUser)
    : rowData;

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Work management</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>매출통계</h1>
              {/* <SelectUserInfo selectUserId={filterUser || ""} /> */}
            </div>
            <div>
              <button className="write_button" onClick={handleOpen}>
                <FileUploadOutlinedIcon />
                매출통계 업로드
              </button>
              <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <select
                        className="year_select"
                        name="year"
                        id="year"
                        value={year}
                        onChange={(e) => {
                          setYear(e.target.value);
                          console.log(year);
                        }}
                      >
                        {Array.from(
                          { length: currentYear - 2023 + 1 },
                          (_, index) => 2023 + index
                        ).map((yearOption) => (
                          <option key={yearOption} value={yearOption}>
                            {yearOption}년
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="uploadBtn_wrapper">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={openFileDialog}
                          style={{ fontWeight: "600" }}
                        >
                          <FileUploadOutlinedIcon
                            style={{ marginRight: "3px", marginLeft: "-3px" }}
                          />{" "}
                          파일 선택
                        </Button>
                        <input
                          ref={fileInputRef}
                          className="upload_input"
                          accept=".xlsx, .xls"
                          type="file"
                          onChange={handleFileUpload}
                          style={{ color: inputColor }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        onClick={handleUpload}
                        disabled={isLoading || previewData.length === 0}
                      >
                        <FileUploadOutlinedIcon
                          style={{ marginRight: "5px", marginLeft: "-5px" }}
                        />
                        업로드
                      </Button>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        overflow: "auto",
                        height: "100%",
                      }}
                    >
                      <h3 style={{ marginBottom: "5px" }}>미리보기</h3>
                      {previewData.length > 0 ? (
                        <table className="preview_table">
                          <thead>
                            <tr>
                              <th>품목 분류</th>
                              <th>영업담당</th>
                              <th>고객사명</th>
                              <th>1월</th>
                              <th>2월</th>
                              <th>3월</th>
                              <th>1분기</th>
                              <th>4월</th>
                              <th>5월</th>
                              <th>6월</th>
                              <th>2분기</th>
                              <th>7월</th>
                              <th>8월</th>
                              <th>9월</th>
                              <th>3분기</th>
                              <th>10월</th>
                              <th>11월</th>
                              <th>12월</th>
                              <th>4분기</th>
                              <th>연간 요약</th>
                            </tr>
                          </thead>
                          <tbody>
                            {previewData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.product}</td>
                                <td>{row.user_name}</td>
                                <td>{row.comp_name}</td>
                                <td>{formatNumber(row.jan)}</td>
                                <td>{formatNumber(row.feb)}</td>
                                <td>{formatNumber(row.mar)}</td>
                                <td>{formatNumber(row.first_q)}</td>
                                <td>{formatNumber(row.apr)}</td>
                                <td>{formatNumber(row.may)}</td>
                                <td>{formatNumber(row.jun)}</td>
                                <td>{formatNumber(row.second_q)}</td>
                                <td>{formatNumber(row.jul)}</td>
                                <td>{formatNumber(row.aug)}</td>
                                <td>{formatNumber(row.sep)}</td>
                                <td>{formatNumber(row.third_q)}</td>
                                <td>{formatNumber(row.oct)}</td>
                                <td>{formatNumber(row.nov)}</td>
                                <td>{formatNumber(row.dec)}</td>
                                <td>{formatNumber(row.fourth_q)}</td>
                                <td>{formatNumber(row.summary)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="noData_box">
                          <ExcelIcon fill="#1976d2" />
                          업로드된 파일이 없습니다.
                        </div>
                      )}
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button variant="outlined" onClick={handleClose}>
                        닫기
                      </Button>
                    </div>
                  </div>
                  <DialogText
                    open={openDialog}
                    content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
                    onCancel={() => setOpenDialog(false)}
                    onClose={handleConfirmClose}
                  />
                </Box>
              </Modal>
            </div>
          </div>
        </div>
        <Box
          id={"month_plan_card"}
          style={{
            height: "fit-content",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <PlanDate setDate={setDate} selectedYDate={year} selectedMDate="No" />
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label htmlFor="user">영업담당 :</label>
            <select
              name="user"
              id="user"
              className="custom-select__user"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="">전체</option>
              {userList.map((userName) => (
                <option key={userName} value={userName}>
                  {userName}
                </option>
              ))}
            </select>
          </div>
        </Box>
        <Card id={"main_card"}>
          <SalesGrid columnDefs={columnDefs} rowData={filteredRowData} />
        </Card>
      </div>
    </>
  );
}
