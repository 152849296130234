import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ListSubheader, List } from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  isSideBarOpenState,
  isAccordionExpendedState,
  loginUserInfoState,
} from "recoil/stateStore";
// Icon
import WorkLogIcon from "@mui/icons-material/FactCheckOutlined";
import CounselingIcon from "@mui/icons-material/SupportAgentOutlined";
import CompanyStatusIcon from "@mui/icons-material/FolderSharedOutlined";
import CarIcon from "@mui/icons-material/TimeToLeaveOutlined";
import MonthIcon from "@mui/icons-material/EditCalendarOutlined";
import YearIcon from "@mui/icons-material/DynamicFeedOutlined";
import CarInfoIcon from "@mui/icons-material/CarCrashOutlined";
import UserSettingIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonSearchIcon from "@mui/icons-material/PersonSearchOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import { SidebarAccordion } from "./SidebarAccordion";

interface SideBarV2 {
  sideBarWidth: number;
}

export default function SideBarV2() {
  const sideBarWidth = 220;
  const [open] = useRecoilState(isSideBarOpenState);
  const [expanded, setExpanded] = useRecoilState(isAccordionExpendedState);

  const location = useLocation();

  useEffect(() => {
    console.log("sidebar");
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(expanded === panel ? "" : panel);
    };

  // const handleClick =
  //   (panel: string, index: number) =>
  //   (event: React.MouseEvent<HTMLDivElement>) => {
  //     setSelectedIndex(`${panel}-${index}`);
  //     setExpanded(panel);
  //   };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: sideBarWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(9)} + 1px)`,
    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(${theme.spacing(1)} + 1px)`,
    // },
  });

  const theme = useTheme(); // Theme을 사용하기 위해 추가
  const drawerStyle = useMemo(
    () => (open ? openedMixin(theme) : closedMixin(theme)),
    [open, theme]
  );

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    width: open ? sideBarWidth : `calc(${theme.spacing(7)} + 1px)`,
    alignItems: "center",
    justifyContent: open ? "flex-start" : "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  // const Drawer = styled(MuiDrawer, {
  //   shouldForwardProp: (prop) => prop !== "open",
  // })(({ theme, open }) => ({
  //   width: sideBarWidth,
  //   flexShrink: 0,
  //   overflowY: "scroll",
  //   whiteSpace: "nowrap",
  //   boxSizing: "border-box",
  //   ...(open && {
  //     ...openedMixin(theme),
  //     "& .MuiDrawer-paper": openedMixin(theme),
  //   }),
  //   ...(!open && {
  //     ...closedMixin(theme),
  //     "& .MuiDrawer-paper": closedMixin(theme),
  //   }),
  // }));

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          ...drawerStyle,
          "& .MuiDrawer-paper": {
            ...drawerStyle,
            top: "61px", // 헤더 높이만큼 아래로 이동
            height: "calc(100% - 61px)", // 전체 높이에서 헤더 높이를 뺌
          },
        }}
      >
        {/* <DrawerHeader sx={{ justifyContent: "left", borderBottom: "none" }}>
          <img
            style={{
              width: open ? "140px" : "40px",
              marginLeft: open ? "15px" : "0px",
              cursor: "pointer",
            }}
            id="logo"
            src={LogoImg}
            alt="CNI Logo"
            onClick={() => handleNavClick("")}
          />
        </DrawerHeader> */}
        {/* <Divider /> */}
        <Box sx={{ height: "100%", overflowY: "auto" }}>
          <List
            id="sideList"
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              open && (
                <ListSubheader component="div" id="nested-list-subheader">
                  Work management
                </ListSubheader>
              )
            }
          >
            <SidebarAccordion
              title="업무일지"
              icon={<WorkLogIcon />}
              panel="panel1"
              onChange={handleChange("panel1")}
              expanded={expanded}
              page=""
              hasDetails={true}
              open={open}
            />

            <SidebarAccordion
              title="업체현황"
              icon={<CompanyStatusIcon />}
              panel="panel2"
              onChange={handleChange("panel2")}
              expanded={expanded}
              page="companies"
              hasDetails={false}
              open={open}
            />

            <SidebarAccordion
              title="상담일지"
              icon={<CounselingIcon />}
              panel="panel3"
              onChange={handleChange("panel3")}
              expanded={expanded}
              page="counseling"
              hasDetails={true}
              open={open}
            />

            <SidebarAccordion
              title="차량운행일지"
              icon={<CarIcon />}
              panel="panel4"
              onChange={handleChange("panel4")}
              expanded={expanded}
              page="carlog"
              hasDetails={true}
              open={open}
            />

            <SidebarAccordion
              title="월간계획서"
              icon={<MonthIcon />}
              panel="panel5"
              onChange={handleChange("panel5")}
              expanded={expanded}
              page="monthplan"
              hasDetails={true}
              open={open}
            />

            <SidebarAccordion
              title="연간계획서"
              icon={<YearIcon />}
              panel="panel6"
              onChange={handleChange("panel6")}
              expanded={expanded}
              page="yearplan"
              hasDetails={true}
              open={open}
            />

            <SidebarAccordion
              title="매출통계"
              icon={<BarChartIcon />}
              panel="panel7"
              onChange={handleChange("panel7")}
              expanded={expanded}
              page="salesSummary"
              hasDetails={false}
              open={open}
            />
          </List>
          <List
            id="sideList"
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{ ...(!open && { display: "none" }) }}
              >
                Company information
              </ListSubheader>
            }
          >
            <SidebarAccordion
              title="차량정보"
              icon={<CarInfoIcon />}
              panel="panel8"
              onChange={handleChange("panel8")}
              expanded={expanded}
              page="carinfo"
              hasDetails={false}
              open={open}
            />

            <SidebarAccordion
              title="사용자정보"
              icon={<PersonSearchIcon />}
              panel="panel9"
              onChange={handleChange("panel9")}
              expanded={expanded}
              page="userinfo"
              hasDetails={false}
              open={open}
            />
          </List>
          <List
            id="sideList"
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{ ...(!open && { display: "none" }) }}
              >
                Settings
              </ListSubheader>
            }
          >
            <SidebarAccordion
              title="계정설정"
              icon={<UserSettingIcon />}
              panel="panel11"
              onChange={handleChange("panel11")}
              expanded={expanded}
              page="usersetting"
              hasDetails={false}
              open={open}
            />
          </List>
        </Box>
      </Drawer>
    </>
  );
}
