import { ResponseCar, getCar } from "api/car";
import WorkLogDrawer from "components/WorkLogDrawer";
import EditCarForm from "components/editForm/EditCarForm";
import ViewCarForm from "components/viewForm/ViewCarFrom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
    open: boolean;
    onClose: (event: any) => void;
    carId: number;
    setUpdatedData: Function;
    setDeletedId: Function;
}
const ViewCarDrawer: React.FC<FormDrawerProps> = ({
    open,
    onClose,
    carId,
    setUpdatedData,
    setDeletedId,
}) => {
    const [isEdit, setIsEdit] = useRecoilState(isEditedState);
    const [carData, setCarData] = useState<ResponseCar>({} as ResponseCar)
    const [isDeleted, setIsDeleted] = useState<boolean>(false);

    useEffect(() => {
        const callCar = async () => {
            const response = await getCar(carId);
            setCarData(response.data);
            setUpdatedData(response.data);
            console.log(response.data, "")
            setIsDeleted(false)
        }
        if (carId !== -1 && !isEdit) {
            callCar();
        }
    }, [carId, isEdit, isDeleted]);

    return (
        <WorkLogDrawer open={open} onClose={onClose}>
            {isEdit ? (
                <div className="write_head">차량정보 수정</div>
            ) : (
                <div className="write_head">차량정보 상세보기</div>
            )}
            {isEdit ? (
                <EditCarForm
                    carData={carData}
                    setCarData={setCarData}
                    // setIsEdit={setIsEdit}
                    carId={carId}
                />
            ) : (
                <ViewCarForm
                    carData={carData}
                    // setIsEdit={setIsEdit}
                    onClose={onClose}
                    carId={carId}
                    setIsDeleted={setIsDeleted}
                />
            )}
        </WorkLogDrawer>
    );
}
export default ViewCarDrawer;