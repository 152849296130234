import { ReactComponent as CartypeIcon } from "assets/writeIcon/cartype.svg";
import { ReactComponent as CarnumIcon } from "assets/writeIcon/carnum.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/name.svg";
import { ReactComponent as HomeIcon } from "assets/writeIcon/home.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as SwapIcon } from "assets/writeIcon/swap.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { ReactComponent as Delete } from "assets/writeIcon/delete.svg";
import { DeleteButton, EditButton, emptyData, formatDate } from "components/Common";
import DialogDelete from "components/DialogDelete";
import { useState } from "react";
import { ResponseCarLog, deleteCarLog } from 'api/carLog';
import { useRecoilState, useSetRecoilState } from "recoil";
import { checkAdminState, isEditedState } from "recoil/stateStore";
import { ResponseCar, deleteCar } from "api/car";
import { Checkbox } from "@mui/material";

interface ViewCarFormProps {
    carData: ResponseCar;
    // setIsEdit: (state: boolean) => void;
    onClose: (event: any) => void;
    carId: number;
    setIsDeleted: Function;
}

const ViewCarForm = ({ carData, onClose, carId, setIsDeleted }: ViewCarFormProps) => {
    const isAdmin = localStorage.getItem("is_admin") || "";
    const [openDialog, setOpenDialog] = useState(false);
    const setIsEdit = useSetRecoilState(isEditedState);

    // 수정버튼
    const onEdit = async () => {
        setIsEdit(true);
        setOpenDialog(false);
    };

    const handleDelete = async () => {
        const response = await deleteCar(carId);
        setOpenDialog(false);
        onClose(false);
        setIsDeleted(true);
        console.log(response.data);
    };

    return (
        <div className="write_list_wrapper">
            <div className="work_option">
                <div>
                    <div className="div_icon" style={{ marginRight: "15px" }}>
                        <CompanyIcon style={{ marginRight: "4px" }} />
                        업체구분
                    </div>
                    <div className="check_radio" >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                type="radio"
                                id="confirm_car"
                                name="is_personal"
                                value="confirm"
                                checked={!carData.is_personal}
                                style={{
                                    marginRight: "4px",
                                }}
                                disabled={true}
                            />
                            <label htmlFor="confirm_car" >
                                법인차량
                            </label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                type="radio"
                                id="personal_car"
                                name="is_personal"
                                value="personal"
                                checked={carData.is_personal}
                                style={{
                                    marginRight: "4px",
                                }}
                                disabled={true}
                            />
                            <label htmlFor="personal_car" style={carData.is_personal ? { color: 'rgb(0,0,0,0.6)', fontWeight: '600' } : { color: "rgb(0,0,0,0.3)" }}>
                                개인차량
                            </label>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="div_icon">
                        <CartypeIcon style={{ marginRight: "4px" }} />
                        차종
                    </div>
                    <span>{carData.car_model}</span>
                </div>
                <div>
                    <div className="div_icon">
                        <CarnumIcon style={{ marginRight: "4px" }} />
                        차량등록번호
                    </div>
                    <span>{carData.car_num}</span>
                </div>
                <div>
                    <div className="div_icon">
                        <UserIcon style={{ marginRight: "4px" }} />
                        사용자
                    </div>
                    <span>
                        {carData.user?.user_id === null ? (
                            "-"
                        ) : (
                            <>
                                {carData.user?.user_name} {carData.user?.role?.role_name}
                            </>
                        )}
                    </span>
                </div>
                <div>
                    <div className="div_icon">
                        <HomeIcon style={{ marginRight: "4px" }} />
                        자택
                    </div>
                    <span>
                        {carData.home_address === "" ? (
                            "-"
                        ) : (
                            <>{carData.home_address}</>
                        )}
                    </span>
                </div>
                <div>
                    <div className="div_icon">
                        <CompanyIcon style={{ marginRight: "4px" }} />
                        근무지
                    </div>
                    <span>
                        {carData.work_address === "" ? (
                            "-"
                        ) : (
                            <>{carData.work_address}</>
                        )}
                    </span>
                </div>
                <div>
                    <div className="div_icon">
                        <SwapIcon style={{ marginRight: "4px" }} />
                        출퇴근 거리
                    </div>
                    <span>
                        {carData.distance === null ? (
                            "-"
                        ) : (
                            <>{carData.distance}</>
                        )}
                    </span>
                </div>
                <div className="textarea_wrapper">
                    <div className="div_icon">
                        <NoteIcon style={{ marginRight: "4px" }} /> 비고
                    </div>
                    <textarea
                        style={{
                            height: "140px",
                            marginTop: "4px",
                            color: "#000"
                        }}
                        name="car_note"
                        value={carData.car_note}
                        disabled={true}
                    />
                </div>
                <div>
                    <div className="div_icon">
                        <Delete
                            style={{
                                marginRight: "4px",
                                fill: "black",
                                fillOpacity: "0.5",
                            }}
                        />
                        삭제 여부
                    </div>
                    <span>
                        <Checkbox
                            sx={{
                                borderColor: "",
                            }}
                            checked={carData.is_deleted ? true : false}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </span>
                </div>
            </div>
            <div className="write_bottom">
                {isAdmin === "true" ? (
                    <>
                        {carData.is_deleted ? ""
                            :
                            <DeleteButton onClickHandler={() => setOpenDialog(true)} />
                        }

                        <EditButton onClickHandler={onEdit} />
                        <DialogDelete
                            open={openDialog}
                            onCancel={() => setOpenDialog(false)}
                            onDelete={handleDelete}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
export default ViewCarForm;