import { useEffect, useState } from "react";
import { ResponseNewMember, getCompanyMember } from "api/member";
import { useLocation } from "react-router-dom";
import WorkLogDrawer from "../WorkLogDrawer";
import ViewMemberForm from "../viewForm/ViewMemberForm";
import EditWorkForm from "components/editForm/EditWorkForm";
import EditMemberForm from "components/editForm/EditMemberForm";
import { useRecoilValue } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface FormDrawerProps {
    open: boolean;
    onClose: (event: any) => void;
    memberId: number;
    setUpdatedData: Function;
    setDeletedId: Function;
}
const ViewMemberDrawer: React.FC<FormDrawerProps> = ({
    open,
    onClose,
    memberId,
    setUpdatedData,
    setDeletedId,
}) => {
    const location = useLocation();
    const compId: number = location.state.id;
    const isEdit = useRecoilValue(isEditedState)
    const [memberData, setMemberData] = useState<ResponseNewMember>({} as ResponseNewMember);

    useEffect(() => {
        const callMember = async () => {
            console.log(compId, "CompId")
            const response = await getCompanyMember(compId, memberId);
            setMemberData(response.data)
            setUpdatedData(response.data);
        }
        if (memberId !== -1 && !isEdit) {
            callMember();
        }
    }, [memberId, isEdit])


    return (
        <WorkLogDrawer open={open} onClose={onClose}>
            {isEdit ? (
                <div className="write_head">업체 담당자 수정</div>
            ) : (
                <div className="write_head">업체 담당자 상세보기</div>
            )}
            {isEdit ? (
                <EditMemberForm
                    memberData={memberData}
                    setMemberData={setMemberData}
                />
            ) : (
                <ViewMemberForm
                    memberData={memberData}
                    onClose={onClose}
                    setDeletedId={setDeletedId}
                />
            )}
        </WorkLogDrawer>
    );
}
export default ViewMemberDrawer;