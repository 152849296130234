import { ResponseMonthPlan, deleteMonthlyPlan } from "api/monthPlan";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { ReactComponent as InprogressIcon } from "assets/writeIcon/inprogress.svg";
import { ReactComponent as CompleteIcon } from "assets/writeIcon/complete.svg";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MoneyIcon } from "assets/writeIcon/money.svg";
import { ReactComponent as ExpectedMoneyIcon } from "assets/writeIcon/expected-money.svg";
import { ReactComponent as DeliveryDateIcon } from "assets/writeIcon/delivery-date.svg";
import { ReactComponent as ExpectedGraphIcon } from "assets/writeIcon/expected-graph.svg";
import { ReactComponent as ExpectedIcon } from "assets/writeIcon/expected.svg";
import { ReactComponent as NotprogressIcon } from "assets/writeIcon/notprogress.svg";
import { ReactComponent as GraphIcon } from "assets/writeIcon/graph.svg";
import { DeleteButton, EditButton } from "components/Common";
import DialogText from "components/DialogText";
import { useState } from "react";
import DialogDelete from "components/DialogDelete";
import { isEditedState } from "recoil/stateStore";
import { useSetRecoilState } from "recoil";


interface ViewPlanFormProbs {
    monthPlanData: ResponseMonthPlan;
    // setIsEdit: (state: boolean) => void;
    onClose: (event: any) => void;
    setDeletedId: Function;
}

const ViewMonthForm = ({ monthPlanData, onClose, setDeletedId }: ViewPlanFormProbs) => {
    const loginUserIdStr = localStorage.getItem("user_id");
    const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
    const [openDialog, setOpenDialog] = useState(false);
    const setIsEdit = useSetRecoilState(isEditedState);

    const handleDelete = async () => {
        if (monthPlanData.mplan_id) {
            const response = await deleteMonthlyPlan(monthPlanData.mplan_id);
            setOpenDialog(false);
            onClose(false);
            setDeletedId(monthPlanData.mplan_id)
            console.log(response.data);
        }
    };

    // 수정버튼
    const onEdit = async () => {
        setIsEdit(true);
        setOpenDialog(false);
    };

    const formatProgress = (str: string) => {
        if (str === "COMPLETE") {
            return (
                <div className="div_icon" style={{ color: "#000" }}>
                    완료 <CompleteIcon />
                </div>
            );
        }
        if (str === "INPROGRESS") {
            return (
                <div className="div_icon" style={{ color: "#000" }}>
                    진행중 <InprogressIcon />
                </div>
            );
        }
        if (str === "NONE") {
            return (
                <div className="div_icon" style={{ color: "#000" }}>
                    예정 <ExpectedIcon />
                </div>
            );
        }
        if (str === "DROP") {
            return (
                <div className="div_icon" style={{ color: "#000" }}>
                    미진행 <NotprogressIcon />
                </div>
            );
        }
    };

    return (
        <div>
            <div className="write_list_wrapper">
                <div className="work_option">
                    <div>
                        <div className="div_icon" style={{ marginRight: "15px" }}>
                            <CalendarIcon style={{ marginRight: "4px" }} />
                            연도·월
                        </div>
                        <span>
                            {monthPlanData.mplan_year}년{" "}
                            {monthPlanData.mplan_month}월
                        </span>
                    </div>
                    <div>
                        <div className="div_icon" style={{ marginRight: "15px" }}>
                            <CompanyIcon style={{ marginRight: "4px" }} />
                            업무구분
                        </div>
                        <div className="check_radio">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                    type="radio"
                                    id="existing_company"
                                    name="is_newCompany"
                                    value="existing"
                                    style={{
                                        marginRight: "4px",
                                    }}
                                    disabled={true}
                                    checked={!monthPlanData.is_newCompany}
                                />
                                <label
                                    htmlFor="existing_company"
                                    style={!monthPlanData.is_newCompany ? { color: 'rgb(0,0,0,0.6)', fontWeight: '600' } : { color: "rgb(0,0,0,0.3)" }}
                                >
                                    기존업체
                                </label>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                    type="radio"
                                    id="existing_company"
                                    name="is_newCompany"
                                    value="existing"
                                    checked={monthPlanData.is_newCompany}
                                    style={{
                                        marginRight: "4px",
                                    }}
                                    disabled={true}
                                />
                                <label
                                    htmlFor="new_company"
                                    style={monthPlanData.is_newCompany ? { color: 'rgb(0,0,0,0.6)', fontWeight: '600' } : { color: "rgb(0,0,0,0.3)" }}
                                >
                                    신규업체
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="div_icon">
                            <UserIcon style={{ marginRight: "4px" }} /> 담당자
                        </div>
                        <span>
                            {monthPlanData.user?.user_name} (
                            {monthPlanData.user?.user_id})
                        </span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <CompanyIcon style={{ marginRight: "4px" }} /> 고객사명
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </div>

                        <span>{monthPlanData.company?.comp_name ?? "-"}</span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <ExpectedMoneyIcon style={{ marginRight: "4px" }} />
                            상담 예상액
                        </div>
                        <span>{monthPlanData.expected_price?.toLocaleString('ko-KR')}원</span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <MoneyIcon style={{ marginRight: "4px" }} />
                            수주액
                        </div>
                        <span>{monthPlanData.confirmed_price?.toLocaleString('ko-KR')}원</span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <DeliveryDateIcon style={{ marginRight: "4px" }} />
                            납품시기
                        </div>
                        <span>{monthPlanData.delivery_date}</span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <ExpectedGraphIcon style={{ marginRight: "4px" }} />
                            예상 수주율
                        </div>
                        <span>{monthPlanData.expected_order_rate}%</span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <GraphIcon style={{ marginRight: "4px" }} />
                            수주율
                        </div>
                        <span>{monthPlanData.order_rate}%</span>
                    </div>
                    <div className="textarea_wrapper">

                        <div className="div_icon">업무내용</div>
                        <textarea
                            name="plan"
                            value={monthPlanData.mplan_note}
                            style={{ color: "#000", height: "110px" }}
                            disabled={true}
                        />

                    </div>
                    <div className="textarea_wrapper">
                        <div className="div_icon" style={{ marginTop: "16px" }}>
                            상세내용 & 영업활동 분석
                        </div>
                        <textarea
                            style={{ color: "#000", height: "110px" }}
                            name="work_content"
                            value={monthPlanData.mplan_detail}
                            disabled={true}
                        />

                    </div>
                    <div className="work_process">
                        <div className="div_icon">
                            <ProgressIcon style={{ marginRight: "4px" }} />
                            진행상황
                        </div>
                        <span style={{ marginLeft: "8px" }}>
                            {formatProgress(monthPlanData.mplan_progress)}
                        </span>
                    </div>
                </div>
                <div className="write_bottom">
                    <>
                        {loginUserId === monthPlanData.user?.user_id ? (
                            <DeleteButton onClickHandler={() => setOpenDialog(true)} />
                        ) : (
                            ""
                        )}
                        {loginUserId === monthPlanData.user?.user_id ? (
                            <EditButton onClickHandler={onEdit} />
                        ) : (
                            ""
                        )}
                        <DialogDelete
                            open={openDialog}
                            onCancel={() => setOpenDialog(false)}
                            onDelete={handleDelete}
                        />
                    </>
                </div>
            </div>
        </div >
    );
}
export default ViewMonthForm;