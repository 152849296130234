import { useRecoilState, useSetRecoilState } from "recoil";
import { autoSelectCompIdState, isEditedState } from "recoil/stateStore";
import { useState } from "react";
import {
  DeleteButton,
  EditButton,
  emptyData,
  formatDate,
} from "components/Common";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as PhoneIcon } from "assets/writeIcon/phone.svg";
import { ReactComponent as ProjectIcon } from "assets/writeIcon/project.svg";
import { ReactComponent as ItemIcon } from "assets/writeIcon/item.svg";
import { ReactComponent as CountIcon } from "assets/writeIcon/count.svg";
import { ReactComponent as PriceIcon } from "assets/writeIcon/price.svg";
import { ReactComponent as SumIcon } from "assets/writeIcon/sum.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as NoteIcon } from "assets/writeIcon/note.svg";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { ReactComponent as InprogressIcon } from "assets/writeIcon/inprogress.svg";
import { ReactComponent as CompleteIcon } from "assets/writeIcon/complete.svg";
import { ReactComponent as ExpectedIcon } from "assets/writeIcon/expected.svg";
import { ReactComponent as NotprogressIcon } from "assets/writeIcon/notprogress.svg";
import DialogDelete from "components/DialogDelete";
import { CounselingType, deleteCons } from "api/counseling";

interface ViewConsFormProps {
  consData: CounselingType;
  // setIsEdit: (state: boolean) => void;
  onClose: (event: any) => void;
  setDeletedId: Function;
}

const ViewConsForm = ({
  consData,
  onClose,
  setDeletedId,
}: ViewConsFormProps) => {
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [openDialog, setOpenDialog] = useState(false);
  const setAutoSelectCompId = useSetRecoilState(autoSelectCompIdState);
  const setIsEdit = useSetRecoilState(isEditedState);

  const handleDelete = async () => {
    const response = await deleteCons(consData.cons_id);
    setOpenDialog(false);
    onClose(false);
    setDeletedId(consData.cons_id);
    console.log(response.data);
  };

  // 수정버튼
  const onEdit = async () => {
    setAutoSelectCompId(consData.company.comp_id);
    setIsEdit(true);
    setOpenDialog(false);
  };

  const formatProgress = (str: string) => {
    if (str === "COMPLETE") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          완료 <CompleteIcon />
        </div>
      );
    }
    if (str === "INPROGRESS") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          진행중 <InprogressIcon />
        </div>
      );
    }
    if (str === "NONE") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          예정 <ExpectedIcon />
        </div>
      );
    }
    if (str === "DROP") {
      return (
        <div className="div_icon" style={{ color: "#000" }}>
          미진행 <NotprogressIcon />
        </div>
      );
    }
  };

  return (
    <div className="write_list_wrapper">
      <div className="work_option">
        <div className="write_date">
          <div className="div_icon" style={{ marginRight: "15px" }}>
            <CalendarIcon style={{ marginRight: "4px" }} />
            일시
          </div>
          <span>{formatDate(consData.cons_date)}</span>
        </div>

        <div>
          <div className="div_icon">
            <UserIcon style={{ marginRight: "4px" }} />
            작성자
          </div>
          <span>
            {consData.user_name} ({consData.user_id})
          </span>
        </div>
        <div>
          <div className="div_icon">
            <CompanyIcon style={{ marginRight: "4px" }} />
            업체명
          </div>
          <span>{emptyData(consData.company?.comp_name)}</span>
        </div>
        <div>
          <div className="div_icon">
            <MemberIcon style={{ marginRight: "4px" }} /> 업체 담당자
          </div>
          <span>
            {consData.member?.member_name} {consData.member?.member_role}
          </span>
        </div>
        <div className="write_member_phone">
          <div className="div_icon">
            <PhoneIcon style={{ marginRight: "4px" }} />
            연락처
          </div>
          <span style={{ color: "#000", margin: 0 }}>
            {consData.member?.member_phone}
          </span>
        </div>
        <div style={{ height: "fit-content" }}>
          <div className="div_icon">
            <ProjectIcon style={{ marginRight: "4px" }} />
            프로젝트
          </div>
          <span>{consData.project}</span>
        </div>
        <div style={{ height: "fit-content" }}>
          <div className="div_icon">
            <ItemIcon style={{ marginRight: "4px" }} />
            품목
          </div>
          <span>{consData.item}</span>
        </div>
        <div>
          <div className="div_icon">
            <CountIcon style={{ marginRight: "4px" }} />
            수량
          </div>
          <span style={{ color: "#4e20a5" }}>{consData.amount}</span>
        </div>
        <div>
          <div className="div_icon">
            <PriceIcon style={{ marginRight: "4px" }} />
            단가
          </div>
          <span style={{ color: "#4e20a5" }}>
            {consData.price} <span style={{ color: "#000" }}>원</span>
          </span>
        </div>
        <div className="write_cons_sum">
          <div className="div_icon">
            <SumIcon style={{ marginRight: "4px" }} />
            합계
          </div>
          <span style={{ color: "#4e20a5", border: "none", fontSize: "16px" }}>
            {consData.amount * consData.price}{" "}
            <span style={{ color: "#000" }}> 원</span>
          </span>
        </div>
        <div className="textarea_wrapper">
          <div className="div_icon">
            <NoteIcon style={{ marginRight: "4px" }} /> 비고
          </div>
          <textarea
            value={consData.cons_note ? consData.cons_note : ""}
            style={{ color: "#000", marginTop: "5px" }}
            name="cons_note"
            disabled={true}
          />
        </div>
        <div className="work_process">
          <div className="div_icon">
            <ProgressIcon style={{ marginRight: "4px" }} />
            진행상황
          </div>
          <span style={{ marginLeft: "8px" }}>
            {formatProgress(consData.result)}
          </span>
        </div>
      </div>
      <div className="write_bottom">
        <>
          {loginUserId === consData.user_id ? (
            <DeleteButton onClickHandler={() => setOpenDialog(true)} />
          ) : (
            ""
          )}
          {loginUserId === consData.user_id ? (
            <EditButton onClickHandler={onEdit} />
          ) : (
            ""
          )}
          <DialogDelete
            open={openDialog}
            onCancel={() => setOpenDialog(false)}
            onDelete={handleDelete}
          />
        </>
      </div>
    </div>
  );
};
export default ViewConsForm;
