import { ResponseMonthPlan, deleteMonthlyPlan } from "api/monthPlan";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MoenyIcon } from "assets/writeIcon/half-money.svg";
import { ReactComponent as RemoveIcon } from "assets/remove.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as AddIcon } from "assets/add.svg";
import { DeleteButton, EditButton, emptyData } from "components/Common";
import DialogText from "components/DialogText";
import { useState } from "react";
import DialogDelete from "components/DialogDelete";
import { ResponseYearPlan, deleteAnnualPlan } from "api/yearPlan";
import { useSetRecoilState } from "recoil";
import { isEditedState } from "recoil/stateStore";


interface ViewPlanFormProbs {
    yearPlanData: ResponseYearPlan;
    // setIsEdit: (state: boolean) => void;
    onClose: (event: any) => void;
    setDeletedId: Function;
}

const ViewYearForm = ({ yearPlanData, onClose, setDeletedId }: ViewPlanFormProbs) => {
    const loginUserIdStr = localStorage.getItem("user_id");
    const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
    const [openDialog, setOpenDialog] = useState(false);
    const setIsEdit = useSetRecoilState(isEditedState);


    const handleDelete = async () => {
        if (yearPlanData.aplan_id) {
            const response = await deleteAnnualPlan(yearPlanData.aplan_id);
            setOpenDialog(false);
            onClose(false);
            setDeletedId(yearPlanData.aplan_id)
            console.log(response.data);
        }
    };

    // 수정버튼
    const onEdit = async () => {
        setIsEdit(true);
        setOpenDialog(false);
    };

    return (
        <div>
            <div className="write_list_wrapper">
                <div className="work_option">
                    <div>
                        <div className="div_icon" style={{ marginRight: "15px" }}>
                            <CalendarIcon style={{ marginRight: "4px" }} />
                            연도·월
                        </div>
                        <span>
                            {yearPlanData.aplan_year}년{" "}
                        </span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <UserIcon style={{ marginRight: "4px" }} /> 담당자
                        </div>
                        <span>
                            {yearPlanData.user?.user_name} (
                            {yearPlanData.user?.user_id})
                        </span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <CompanyIcon style={{ marginRight: "4px" }} /> 업체명
                        </div>
                        <span>{yearPlanData.company !== undefined ? emptyData(yearPlanData.company?.comp_name) : ""}</span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <PartIcon style={{ marginRight: "4px" }} /> 지역
                        </div>
                        <span>
                            {yearPlanData.company?.comp_id === null ||
                                yearPlanData.company?.comp_id === -1
                                ? "-"
                                : yearPlanData.company?.comp_sido_nm +
                                " " +
                                yearPlanData.company?.comp_sgg_nm}
                        </span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <PartIcon style={{ marginRight: "4px" }} /> 업종
                        </div>
                        <span>{yearPlanData.company?.sectors}</span>
                    </div>
                    <div>
                        분기별 매출액 <div>(단위: 만원)</div>{" "}
                    </div>
                    <div>
                        <div className="div_icon">
                            <MoenyIcon style={{ marginRight: "4px" }} />
                            1분기
                        </div>
                        <span>
                            {yearPlanData.first_quarter
                                ? yearPlanData.first_quarter.toLocaleString('ko-KR')
                                : 0}{" "}
                            원
                        </span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <MoenyIcon style={{ marginRight: "4px" }} />
                            2분기
                        </div>
                        <span>
                            {yearPlanData.second_quarter
                                ? yearPlanData.second_quarter.toLocaleString('ko-KR')
                                : 0}{" "}
                            원
                        </span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <MoenyIcon style={{ marginRight: "4px" }} />
                            3분기
                        </div>
                        <span>
                            {yearPlanData.third_quarter
                                ? yearPlanData.third_quarter.toLocaleString('ko-KR')
                                : 0}{" "}
                            원
                        </span>
                    </div>
                    <div>
                        <div className="div_icon">
                            <MoenyIcon style={{ marginRight: "4px" }} />
                            4분기
                        </div>
                        <span>
                            {yearPlanData.fourth_quarter
                                ? yearPlanData.fourth_quarter.toLocaleString('ko-KR')
                                : 0}{" "}
                            원
                        </span>
                    </div>
                    <div className="textarea_wrapper">
                        <div className="div_icon">
                            업체별 진행현황
                        </div>
                        <textarea
                            value={yearPlanData.comp_progress}
                            name="plan"
                            style={{ color: "#000", height: "100px" }}
                            disabled={true}
                        />
                    </div>
                    <div className="textarea_wrapper">
                        <div className="div_icon" style={{ marginTop: "16px" }}>
                            영업계획
                        </div>
                        <textarea
                            value={yearPlanData.sales_plan}
                            style={{ color: "#000", height: "100px" }}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="">
                    <div>
                        <div className="members_wrapper">
                            <span
                                style={{ paddingTop: "5px", color: "rgb(0, 0, 0, 0.6)" }}
                            >
                                주요 담당자
                            </span>
                            <span style={{ paddingTop: "5px" }}>Key</span>
                            {yearPlanData.comp_members?.length != 0 ? (
                                <div className="select_members">
                                    {yearPlanData.comp_members?.map((member, index) => (
                                        <div key={index} style={{ marginBottom: "10px" }}>
                                            <select
                                                disabled
                                                value={member.member_id}
                                                style={{ width: "150px" }}
                                            >
                                                <option
                                                    key={member.member_id}
                                                    value={member.member_id}
                                                >
                                                    {member.member_name} {member.member_role}
                                                </option>
                                            </select>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div>-</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="write_bottom">
                    <>
                        {loginUserId === yearPlanData.user?.user_id ? (
                            <DeleteButton onClickHandler={() => setOpenDialog(true)} />
                        ) : (
                            ""
                        )}
                        {loginUserId === yearPlanData.user?.user_id ? (
                            <EditButton onClickHandler={onEdit} />
                        ) : (
                            ""
                        )}
                        <DialogDelete
                            open={openDialog}
                            onCancel={() => setOpenDialog(false)}
                            onDelete={handleDelete}
                        />
                    </>
                </div>
            </div>
        </div>
    );
}
export default ViewYearForm;