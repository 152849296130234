import { useEffect, useState } from "react";
import { CancelButton, SaveButton } from "components/Common";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { autoSelectCompIdState, isEditedState } from "recoil/stateStore";
import { DialogText } from "components/DialogText";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as WorkOptionIcon } from "assets/writeIcon/work_option.svg";
import { ReactComponent as MeetOptionIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as PartIcon } from "assets/writeIcon/part.svg";
import { ReactComponent as MemberIcon } from "assets/writeIcon/member.svg";
import { ReactComponent as PlanIcon } from "assets/writeIcon/plan.svg";
import { ReactComponent as ResultIcon } from "assets/writeIcon/content.svg";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { ResponseWorkLog, patchWorkLog } from "api/workLog";
import { useErrorHandler } from "components/ErrorHandler";
import AutoCompleteCompany from "components/AutoCompleteCompany";
import { Member, getCompanyMember, getCompanyMembers } from "api/member";
import { SelectInput } from "components/SelectInput";
import { workListState } from "recoil/stateStore";

interface EditWorkFormProps {
  workLogData: ResponseWorkLog;
  setWorkLogData: Function;
  // setIsEdit: (state: boolean) => void;
}

const EditWorkForm: React.FC<EditWorkFormProps> = ({
  workLogData,
  setWorkLogData,
  // setIsEdit,
}) => {
  const today = new Date();
  const loginUsername = localStorage.getItem("user_name");
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const handleError = useErrorHandler();
  const [openDialog, setOpenDialog] = useState(false);
  const autoSelectCompId = useRecoilValue(autoSelectCompIdState);
  const [members, setMembers] = useState<Member[]>([]);
  const [memberPart, setMemberPart] = useState<string>("-");
  const [regionItem, setRegionItem] = useState<string>("고객사 위치");
  const [workDate, setWorkDate] = useState<Date>(workLogData.work_date);
  const [compId, setCompId] = useState<number | null>(
    workLogData.company.comp_id
  );
  const [memberId, setMemberId] = useState<number | null>(
    workLogData.member.member_id
  );
  const [workOption, setWorkOption] = useState<string>(workLogData.work_option);
  const [meeting, setMeetingOption] = useState<string>(workLogData.meeting);
  const [content, setContent] = useState<string>(workLogData.work_content);
  const [plan, setPlan] = useState<string>(workLogData.plan);
  const [progress, setProgress] = useState<string>(workLogData.progress);
  const setIsEdit = useSetRecoilState(isEditedState);
  const setWorkList = useSetRecoilState(workListState);
  const [date, setDate] = useState<Date>(workDate);
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    setCompId(autoSelectCompId);
    setWorkDate(workLogData.work_date);
    setTime(formatTime(workLogData.work_date));

    if (autoSelectCompId) {
      const callMambers = async () => {
        try {
          const response = await getCompanyMembers(autoSelectCompId);
          const memberData = response.data.content;
          setMembers(memberData.member_list);
          setRegionItem(
            workLogData.company?.comp_sido + " " + workLogData.company?.comp_sgg
          );
        } catch (error) {
          console.log("no members");
        }
      };
      callMambers();
    }
  }, [autoSelectCompId, workLogData]);

  const formatTime = (date: Date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // 진행완료
  const handleProgress = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProgress(e.target.value);
  };

  // 취소버튼
  const onCancel = () => {
    setOpenDialog(true);
    // setIsEdit(false);
  };

  // 저장버튼
  const handleSubmit = async () => {
    console.log("수정 저장");
    try {
      const combinedDateTime = new Date(date);
      const [hours, minutes] = time.split(":").map(Number);
      combinedDateTime.setHours(hours, minutes);

      console.log(combinedDateTime, "combinedDateTime");

      const data = {
        work_date: combinedDateTime,
        user_id: loginUserId,
        comp_id: compId,
        member_id: memberId,
        work_option: workOption,
        work_content: content,
        meeting: meeting,
        plan: plan,
        progress: progress,
      };

      const updateWorkLog = await patchWorkLog(workLogData.work_id, data);
      setWorkLogData(updateWorkLog);
      setWorkList((prev) => [...prev, updateWorkLog]);
      setIsEdit(false);
    } catch (error) {
      handleError(error); // 오류 처리 함수 호출
      console.log("edited worklog not saved");
    }
  };

  // 담당자 선택 - 부서값 자동 채워짐
  // 고객사 담당자에 대한 부서값 조회
  const handleSelectMember = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedMemberId = parseInt(event.target.value);
    setMemberId(selectedMemberId);

    if (compId !== null) {
      if (!isNaN(selectedMemberId) && selectedMemberId !== -1) {
        try {
          const memberInfo = await getCompanyMember(compId, selectedMemberId);
          console.log(memberInfo, "memberInfo");
          setMemberPart(memberInfo.member_department);
        } catch (error) {
          console.error(error);
        }
      } else {
        setMemberId(null);
        setMemberPart("-");
      }
    }
  };

  // 업무일지 날짜
  const handleWorkDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    setDate(selectedDate);
    console.log(selectedDate, "selectedDate");
  };

  // 시간 선택 핸들러 추가
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };

  const workOptionOptions = [
    { id: "TECHNICAL_EXTERNAL", name: "현장대응" },
    { id: "TECHNICAL_INTERNAL", name: "전화대응" },
    { id: "INCOMPANY", name: "사내업무" },
    { id: "DELIVERY", name: "납품" },
    { id: "MEETING", name: "미팅" },
    { id: "EDUCATION", name: "교육" },
    { id: "OTHER", name: "기타" },
  ];

  const meetingOptions = [
    { id: "CALL", name: "전화" },
    { id: "INPERSON", name: "대면" },
    { id: "INTERNAL", name: "내근" },
    { id: "OTHER", name: "기타" },
  ];

  return (
    <div>
      <div className="write_list_wrapper">
        <div className="write_content">
          <div className="write_date">
            <div className="div_icon" style={{ marginRight: "15px" }}>
              <CalendarIcon style={{ marginRight: "4px" }} />
              일시
            </div>
            <div className="date_time_input__box">
              <input
                type="date"
                value={new Date(date).toISOString().split("T")[0]}
                onChange={handleWorkDate}
              />
              <input type="time" value={time} onChange={handleTimeChange} />
            </div>
          </div>
          <div>
            <div className="div_icon">
              <UserIcon style={{ marginRight: "4px" }} /> 담당자
            </div>
            <span>
              {loginUsername} ({loginUserId})
            </span>
          </div>
          <div>
            <div className="div_icon">
              <WorkOptionIcon style={{ marginRight: "4px" }} /> 업무구분
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <SelectInput
              id="work_option"
              value={workOption}
              onChange={({ target }) => setWorkOption(target.value)}
              options={workOptionOptions}
            />
          </div>
          <div>
            <div className="div_icon">
              <MeetOptionIcon style={{ marginRight: "4px" }} /> 미팅방식
            </div>
            <SelectInput
              id="meeting"
              value={meeting}
              onChange={({ target }) => setMeetingOption(target.value)}
              options={meetingOptions}
            />
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} /> 지역
            </div>
            <span>{regionItem}</span>
          </div>
          <div>
            <div className="div_icon">
              <CompanyIcon style={{ marginRight: "4px" }} /> 고객사명
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <AutoCompleteCompany />
          </div>
          <div>
            <div className="div_icon">
              <MemberIcon style={{ marginRight: "4px" }} /> 고객사 담당자
            </div>
            <select
              disabled={compId === null || compId === -1}
              value={memberId != null ? memberId : -1}
              onChange={handleSelectMember}
            >
              {compId === null || compId === -1 ? (
                <option value="-1">고객사를 먼저 선택하세요</option>
              ) : (
                <>
                  <option value="-1">선택하세요</option>
                  {members.map((member) => (
                    <option key={member.member_id} value={member.member_id}>
                      {member.member_name} {member.member_role}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div>
            <div className="div_icon">
              <PartIcon style={{ marginRight: "4px" }} />
              고객사 부서
            </div>
            <span>{memberPart}</span>
          </div>

          <div className="textarea_wrapper">
            <div className="div_icon">
              <PlanIcon style={{ marginRight: "4px" }} /> 계획일정
            </div>
            <textarea
              value={plan}
              name="plan"
              onChange={({ target }) => setPlan(target.value)}
            />
          </div>
          <div className="textarea_wrapper">
            <div className="div_icon">
              <ResultIcon style={{ marginRight: "4px" }} /> 대응내용 및 결과
            </div>
            {/* <textarea
                            value={workLogData.work_content}
                            name="work_content"
                            onChange={handleContentChange}
                            disabled={false}
                        /> */}
            <textarea
              style={{ height: "140px", marginTop: "4px", color: "#000" }}
              name="work_content"
              value={content}
              onChange={({ target }) => setContent(target.value)}
            />
          </div>
          <div className="work_process">
            <div className="div_icon">
              <ProgressIcon style={{ marginRight: "4px" }} />
              진행상황
            </div>
            <select value={progress} onChange={handleProgress}>
              <option value="EXPECTED">예정</option>
              <option value="INPROGRESS">진행중</option>
              <option value="NOTPROGRESS">미진행</option>
              <option value="COMPLETE">완료</option>
            </select>
          </div>
        </div>

        <div className="write_bottom">
          <>
            <DialogText
              open={openDialog}
              content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
              onCancel={() => setOpenDialog(false)}
              onClose={() => setIsEdit(false)}
            />
            <CancelButton onClickHandler={onCancel} />
            <SaveButton onClickHandler={handleSubmit} />
          </>
        </div>
      </div>
    </div>
  );
};
export default EditWorkForm;
