import { client } from "api/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userState } from "recoil/stateStore";

// export default LogoutComponent;
const useLogout = () => {
    const navigate = useNavigate();
    const setUser = useSetRecoilState(userState);

    const handleLogout = () => {
        // localStorage에서 토큰 삭제
        localStorage.removeItem("token");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_role");
        localStorage.removeItem("is_admin");
        setUser({ user_id: "", password: "" })

        // 사용자에게 알림
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");

        // 로그인 페이지로 이동
        navigate("/login");
    };

    client.interceptors.response.use(
        (response) => {
            // 정상 응답일 경우
            return response;
        },
        (error) => {
            // 에러 응답이 401일 경우 (토큰 만료)
            const token = localStorage.getItem("token");
            if (token && error.response && error.response.status === 401 || error.response.status === 403) {
                console.log("토큰 만료, 로그아웃 처리");

                // localStorage에서 토큰 삭제 (로그아웃)
                localStorage.removeItem("token");
                localStorage.removeItem("user");

                // 로그아웃 이벤트 트리거
                // window.dispatchEvent(new Event("logout"));
                handleLogout();

                return;
            }
            return Promise.reject(error);
        }
    );
}

export default useLogout;