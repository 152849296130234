import React, { useState, useEffect } from "react";
import { ColDef, ITextFilterParams } from "ag-grid-community";
import Card from "@mui/material/Card";
import Grid from "components/Grid";
import Box from "@mui/material/Box";
import { ReactComponent as CompanyIcon } from "assets/company.svg";
import { Company, getCompanyList, getTotalCompanyList } from "api/company";
import { textFilterParams } from "components/Common";
import CreateWorkDrawer from "components/drawer/CreateWorkDrawer";
import DialogText from "components/DialogText";

interface RowData {
  comp_name: string;
  sectors: string;
  address: string;
  phone: string;
  fax: string;
}

export default function WorkStatus() {
  const [rowData, setRowData] = useState<RowData[]>([]);

  const [companyDrawerOpen, setCompanyDrawerOpen] = useState<boolean>(false);
  const [selectedCompId, setSelectedCompId] = useState<number>(-1);
  const [userDrawerOpen, setUserDrawerOpen] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [viewSize, setViewSize] = useState<number>(10);

  useEffect(() => {
    const callCompanyList = async () => {
      try {
        const { data } = await getTotalCompanyList();
        const mappedData = data.content.map((item: Company) => ({
          comp_id: item.comp_id,
          comp_name: item.comp_name,
          sector: item.sectors,
          address:
            item.comp_sido_nm +
            " " +
            item.comp_sgg_nm +
            " " +
            item.comp_address,
          comp_tel: item.comp_tel,
          comp_fax: item.comp_fax,
        }));
        setRowData(mappedData);
        console.log(data.content, "companyList");
      } catch (error) {
        console.error("Error fetching work logs:", error);
      }
    };
    callCompanyList();
  }, [setRowData, companyDrawerOpen]);

  const columnDefs: ColDef[] = [
    {
      headerName: "ID",
      field: "comp_id",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 0.7,
    },
    {
      headerName: "업체명",
      field: "comp_name",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "업종",
      field: "sector",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "주소",
      field: "address",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1.8,
    },
    {
      headerName: "전화번호",
      field: "comp_tel",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
    {
      headerName: "팩스번호",
      field: "comp_fax",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      flex: 1,
    },
  ];

  const selectedId = (type: string, id: number) => {
    setSelectedCompId(id);
  };

  const handleSetOpen = (status: boolean, type: string) => {
    setCompanyDrawerOpen(true);
    setUserDrawerOpen("create");
  };

  const closeDrawer = () => {
    setOpenDialog(true);
  };

  const handleSubmitSuccess = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setCompanyDrawerOpen(false);
    setOpenDialog(false);
  };

  const pageSizeChange = (size: number) => {
    setViewSize(size);
  };

  return (
    <>
      <div className="mainTop_wrapper">
        <div className="title">
          <div className="menu">Work management</div>
          <div className="sub_menu">
            <div className="sub_menu__title">
              <h1>업체현황</h1>
            </div>
            <div>
              <button
                className="write_button"
                onClick={() => handleSetOpen(true, "Company")}
              >
                <CompanyIcon />
                신규 업체 등록
              </button>
            </div>
          </div>
        </div>
        <Card id={"main_card"}>
          <Grid
            columnDefs={columnDefs}
            rowData={rowData}
            type="workStatus"
            setOpen={handleSetOpen}
            setId={selectedId}
            setViewSize={pageSizeChange}
          />
          <CreateWorkDrawer
            open={userDrawerOpen === "create" && companyDrawerOpen}
            onClose={closeDrawer}
            onSubmitSuccess={handleSubmitSuccess}
            type={"Company"}
          />
          <DialogText
            open={openDialog}
            content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
            onCancel={() => setOpenDialog(false)}
            onClose={closeDialog}
          />
        </Card>
      </div>
    </>
  );
}
