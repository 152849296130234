import { ResponseWorkLog } from "api/workLog";
import {
  convertRegionName,
  formatDate,
  handleMeetOptionText,
  handleWorkOptionText,
} from "components/Common";

interface RowData {
  work_id: number;
  work_date: string;
  user_name: string;
  work_option: string;
  meeting: string;
  comp_address: string;
  company_name: string;
  member_name: string;
  plan: string;
  work_content: string;
  progress: string;
}

export const serializeWorkLogs = (content: ResponseWorkLog[]): RowData[] => {
  const mappedData = content.map((item: ResponseWorkLog) => {
    const workDate = new Date(item.work_date);
    const workHour = !isNaN(workDate.getTime())
      ? workDate.getHours().toString().padStart(2, "0")
      : "-";
    const workMinute = !isNaN(workDate.getTime())
      ? workDate.getMinutes().toString().padStart(2, "0")
      : "-";
    return {
      work_id: item.work_id,
      work_date: formatDate(item.work_date),
      work_hour: workHour,
      work_minute: workMinute,
      user_name: item.user?.user_name || "-",
      work_option: handleWorkOptionText(item.work_option) || "-",
      meeting: handleMeetOptionText(item.meeting) || "-",
      comp_address: item.company?.comp_sido
        ? convertRegionName(item.company.comp_sido) +
          " " +
          (item.company.comp_sgg || "")
        : "-",
      company_name: item.company?.comp_name || "-",
      comp_id: item.company?.comp_id,
      member_name: item.member?.member_name || "-",
      plan: item.plan || "-",
      work_content: item.work_content || "-",
      progress: item.progress || "-",
      user_id: item.user?.user_id || "-",
    };
  });
  return mappedData;
};

export const serializeWorkLog = (content: ResponseWorkLog): RowData => {
  const mappedData = serializeWorkLogs([content]);
  return mappedData[0];
};
